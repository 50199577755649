/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState, Fragment } from "react";
import {
  Add,
  BarChart,
  ChevronRight,
  CreditCard,
  Groups,
  Money,
  PermIdentity,
  PieChart,
  Settings,
  ShowChart,
  VerticalAlignBottom,
  VerticalAlignTop,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Typography,
  // Grid as Grid2,
  Unstable_Grid2 as Grid2,
} from "@mui/material";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileAppBar } from "../../components/Appbar";
import {
  AdminDetailModal,
  ProposalDetailModal,
  TransactionDetailModal,
} from "../../components/dialog";
import { Select } from "../../components/input";
import Header from "../../components/header";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";

const DashboardPage = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const state = location.state;
  const { wepID } = state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const enterprise = enterprises.tempEnterprise;

  const [logo] = useState(enterprise.info.logo);
  const [name] = useState(enterprise.info.name);
  const [ID] = useState(enterprise.info.wepID);
  const [treasury] = useState(8350);
  const [sharedValue] = useState(4520);
  const [myShare] = useState(3550);
  const [totalShare] = useState(20000);
  const [totalMembers] = useState(252);
  const [proposals, setProposals] = useState([]);
  const [transactions, setTrnasactions] = useState([]);
  const [admins, setAdmins] = useState(enterprise.admins);

  useEffect(() => {
    setStep(STEP.INDEX);
    console.log("============", wepID);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(enterprise);
    setAdmins(enterprise.admins);
    // proposals
    let proposals = [];
    let totalVotes = 1000;
    enterprise.proposals.forEach((proposal, idx) => {
      proposals = [
        ...proposals,
        {
          id: idx,
          name: proposal.name,
          address: proposal.walletAddr,
          type: proposal.type,
          yesPercent:
            proposal.votesYes + proposal.votesNo === 0
              ? 0
              : (proposal.votesYes / (proposal.votesYes + proposal.votesNo)) *
                100,
          totalPercent:
            totalVotes === 0 ? 0 : (proposal.votesYes / totalVotes) * 100,
        },
      ];
    });
    setProposals(proposals);
    // transactions
    let transactions = [];
    enterprise.transactions.forEach((transaction, idx) => {
      transactions = [
        ...transactions,
        {
          id: idx,
          type: transaction.type,
          asset: transaction.asset,
          category: transaction.category,
          amount: transaction.amount,
          toAddress: transaction.toAddress,
          fromAddress: transaction.fromAddress,
          note: transaction.note,
          date: transaction.date,
        },
      ];
    });

    setTrnasactions(enterprise.transactions);
  }, [enterprise]); // eslint-disable-line react-hooks/exhaustive-deps

  const back = () => {
    navigation("/");
  };

  const setting = () => {
    navigation(`/wepID/setting`, { state: { wepID } });
  };

  const send = () => {
    navigation(`/wepID/send`, { state: { wepID } });
  };

  const receive = () => {
    navigation(`/wepID/receive`, { state: { wepID } });
  };

  const trade = () => {
    setStep(STEP.DASHBOARD_TRADE_ADD);
    navigation(`/wepID/trades`, { state: { wepID } });
  };

  const allMembers = () => {
    navigation(`/wepID/members`, { state: { wepID } });
  };

  const proposalsAll = () => {
    navigation(`/wepID/proposals`, { state: { wepID } });
  };

  const trxAll = () => {
    navigation(`/wepID/transactions`, { state: { wepID } });
  };

  // const adminAdd = () => {
  //   setStep(STEP.ADMIN_ADD)
  //   navigation(`/wepID/admins`, {state: {wepID}})
  // }

  const adminsAll = () => {
    navigation(`/wepID/admins`, { state: { wepID } });
  };

  const adminClickHandler = (idx) => {
    setAdminIdx(idx);
    setAdminDlgOpen(true);
  };

  const proposalClickHandler = (idx) => {
    setProposalIdx(idx);
    setProposalDlgOpen(true);
  };

  const trxClickHandler = (idx) => {
    setTrxIdx(idx);
    setTrxDlgOpen(true);
  };

  // Admin View Dialog
  const [adminDlgOpen, setAdminDlgOpen] = useState(false);
  const [adminIdx, setAdminIdx] = useState(-1);
  const adminReplaceHandler = (idx) => {
    updateEnterprises((prev) => {
      prev.toEditAdmin = idx;
      return prev;
    });
    setStep(STEP.DASHBOARD_ADMIN_EDIT);
    navigation(`/wepID/admins`, { state: { wepID } });
    setAdminDlgOpen(false);
  };
  const adminRemoveHndler = (idx) => () => {
    updateEnterprises((prev) => {
      prev.tempEnterprise.admins.splice(idx, 1);
      return prev;
    });
    setAdminDlgOpen(false);
  };

  // Porposal View Dialog
  const [proposalDlgOpen, setProposalDlgOpen] = useState(false);
  const [proposalIdx, setProposalIdx] = useState(-1);
  const proposalReject = () => {
    setProposalDlgOpen(false);
  };
  const proposalAgree = () => {
    setProposalDlgOpen(false);
  };

  // Tramsaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const Chart = () => {
    navigation(`/wepID/chart`, { state: { wepID } });
  };

  return (
    <>
      <Header title={wepID} />
      <Box component="main" sx={{ backgroundColor: "#F2F2F2" }}>
        <ProfileAppBar
          logo={logo}
          name={name}
          ID={ID || ""}
          back={back}
          SecondIcon={Settings}
          secondAction={setting}
        />
        <Grid2 px={2} pb={3} mt={-9} spacing={5} component="main">
          <Grid2 sx={{ background: "#ffffff", borderRadius: "8px" }}>
            <Grid2 container px="15px" py="18px">
              <Grid2 xs={6}>
                <Typography variant="body1">Treasury</Typography>
                <Container
                  sx={{
                    mt: "5px",
                    p: 0,
                    display: "flex",
                    color: "#3D61B0",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      mr: "4px",
                      color: "#3D61B0",
                    }}
                  >
                    $
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      color: "#3D61B0",
                    }}
                  >
                    {treasury.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Container>
                <Select
                  id="assets"
                  value="All assets"
                  sx={{
                    mt: "5px",
                    alignItems: "flex-end",
                    "& .MuiSelect-select": {
                      height: "18px",
                      // "-webkit-text-fill-color": "#4B4749",
                      WebkitTextFillColor: "#4B4749",
                      backgroundColor: "#FFFFFFFF",
                      borderColor: "#FFFFFFFF",
                      p: 0,
                      paddingLeft: 2,
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                    },
                  }}
                >
                  <MenuItem value="All assets">All assets</MenuItem>
                </Select>
              </Grid2>
              <Grid2 xs={6}>
                <Typography variant="body1">Share Value</Typography>
                <Container
                  sx={{
                    mt: "5px",
                    p: "0!important",
                    display: "flex",
                    color: "#3D61B0",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "20px",
                      mr: "4px",
                      color: "#3D61B0",
                    }}
                  >
                    $
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      lineHeight: "29px",
                      color: "#3D61B0",
                    }}
                  >
                    {sharedValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Typography>
                </Container>
                <Typography
                  mt="5px"
                  variant="body1"
                  sx={{ height: "18px", alignItems: "flex-end" }}
                >
                  1ABC=$1.25
                  <Button
                    variant="outlined"
                    sx={{
                      p: 0,
                      ml: 1,
                      height: "16px",
                      width: "21px",
                      minWidth: "0px",
                      backgroundColor: "#FFFFFFFF",
                      border: "1px solid #E3E8EB",
                      ":hover": {
                        backgroundColor: "#FFFFFFFF",
                        border: "1px solid #E3E8EB",
                      },
                    }}
                    onClick={() => {
                      Chart();
                    }}
                  >
                    <ShowChart
                      fontSize="small"
                      htmlColor="#00C9F2"
                      sx={{ height: "18px" }}
                    />
                  </Button>
                </Typography>
              </Grid2>
            </Grid2>
            <Divider />
            <Grid2
              container
              p="15px"
              spacing={2}
              onClick={() => {
                allMembers();
              }}
            >
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoCard
                  LogoIcon={BarChart}
                  title="My Shares"
                  num={myShare}
                  secNum={(myShare / totalShare) * 100}
                />
              </Grid2>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoCard
                  LogoIcon={PieChart}
                  title="Total Shares"
                  num={totalShare}
                />
              </Grid2>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoCard
                  LogoIcon={Groups}
                  title="Total Members"
                  num={totalMembers}
                />
              </Grid2>
            </Grid2>
            <Grid2 container p="0px 15px 15px 15px" spacing={2}>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoButton
                  BtnIcon={VerticalAlignTop}
                  BtnText="Send"
                  onClick={() => {
                    send();
                  }}
                />
              </Grid2>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoButton
                  BtnIcon={VerticalAlignBottom}
                  BtnText="Receive"
                  onClick={() => {
                    receive();
                  }}
                />
              </Grid2>
              <Grid2
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <InfoButton
                  BtnIcon={Money}
                  BtnText="Trade"
                  onClick={() => {
                    trade();
                  }}
                />
              </Grid2>
            </Grid2>
          </Grid2>
          {/* Proposals */}
          <Section
            key="proposals"
            title="Proposals to approve"
            seeAll={proposalsAll}
            sx={{ height: "140px", overflow: "auto" }}
          >
            <List>
              {proposals.map((v, i) => {
                return (
                  <Fragment key={i}>
                    <ListItemButton
                      key={`proposals-${i}`}
                      sx={{
                        px: 0,
                        pt: 1,
                        pb: proposals.length - 1 === i ? 0 : 1,
                        width: "100%",
                      }}
                      onClick={() => {
                        proposalClickHandler(i);
                      }}
                    >
                      <ListItemText
                        primary={v.name !== "" ? v.name : v.address}
                        secondary={(() => {
                          switch (v.type) {
                            case "admin":
                              return "New Admin Request";
                            case "shareholder":
                              return "New Shareholder Request";
                            case "join":
                              return "New Join Request";
                            case "adminReplace":
                              return "Admin Replace Request";
                            default:
                              return "";
                          }
                        })()}
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "18px",
                          color: "#241F21",
                          textAlign: "left",
                          maxWidth: "22ch",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        secondaryTypographyProps={{
                          mt: 1,
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "#4B4749",
                          textAlign: "left",
                        }}
                        sx={
                          {
                            // display: "block",
                            // width: "100%"
                          }
                        }
                      />
                      <ListItemText
                        primary={v.yesPercent.toFixed(2) + "%"}
                        secondary={v.totalPercent.toFixed(2) + "%"}
                        primaryTypographyProps={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "15px",
                          lineHeight: "18px",
                          textAlign: "right",
                          color: "#42B03D",
                        }}
                        secondaryTypographyProps={{
                          mt: 1,
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "15px",
                          textAlign: "right",
                          color: "#FF6142",
                        }}
                        sx={
                          {
                            // display: "block",
                            // width: "100%"
                          }
                        }
                      />
                    </ListItemButton>
                    {proposals.length - 1 !== i && (
                      <Divider key={`proposals-divider-${i}`} />
                    )}
                  </Fragment>
                );
              })}
            </List>
          </Section>
          {/* Recent transactions */}
          <Section
            key="trx"
            title="Recent Transactions"
            seeAll={trxAll}
            sx={{ height: "130px", overflow: "auto" }}
          >
            <TransactionList
              transactions={transactions}
              // onClickItem={() => {
              //   trxClickHandler();
              // }}
              onClickItem={trxClickHandler}
            />
          </Section>
          <Section
            key="admins"
            title={`Admins (${admins.length})`}
            seeAll={adminsAll}
          >
            {/* <Grid2 container spacing={2} xs={12} mt={2}> */}
            <Box overflow="auto" whiteSpace="nowrap">
              {admins.map((admin, idx) => {
                return (
                  // <Grid2 key={idx} display="flex" flexDirection="column" justifyContent="center" alignItems="center" xs={4}>
                  <Box
                    key={idx}
                    sx={{
                      display: "inline-flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      // width: "120px",
                      m: 2,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      adminClickHandler(idx);
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: "#FAFBFC",
                        width: "68px",
                        height: "68px",
                        borderColor: "#FFFFFFFF",
                      }}
                    >
                      <PermIdentity
                        htmlColor="#4B4749"
                        sx={{ width: "30px", height: "30px" }}
                      />
                    </Avatar>
                    <Typography
                      variant="body1"
                      sx={{ mt: 1, lineHeight: "15px", alignItems: "flex-end" }}
                    >
                      {admin.fullName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        alignItems: "flex-end",
                        color: admin.isActive ? "#42B03D" : "#979797",
                      }}
                    >
                      &#x2022; {admin.isActive ? "Active" : "Pending"}
                    </Typography>
                  </Box>
                  // </Grid2>
                );
              })}
            </Box>
            {/* </Grid2> */}
          </Section>
        </Grid2>
      </Box>
      <AdminDetailModal
        open={adminDlgOpen}
        onClose={() => {
          setAdminDlgOpen(false);
        }}
        email={(admins[adminIdx] && admins[adminIdx].email) || ""}
        phone={(admins[adminIdx] && admins[adminIdx].phone) || ""}
        walletAddr={(admins[adminIdx] && admins[adminIdx].walletAddr) || ""}
        replace={() => {
          adminReplaceHandler(adminIdx);
        }}
        remove={() => {
          adminRemoveHndler(adminIdx);
        }}
      />
      <ProposalDetailModal
        open={proposalDlgOpen}
        handleClose={() => setProposalDlgOpen(false)}
        reject={proposalReject}
        agree={proposalAgree}
        name={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].name) ||
          ""
        }
        walletAddr={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].walletAddr) ||
          ""
        }
        id={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].id.toString()) ||
          ""
        }
        yesNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesYes
        }
        noNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesNo
        }
      />
      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={enterprise.transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
        // asset={enterprise.transactions[trxIdx].asset}
        // category={enterprise.transactions[trxIdx].category}
        // amount={enterprise.transactions[trxIdx].amount}
        // to={enterprise.transactions[trxIdx].to}
        // from={enterprise.transactions[trxIdx].from}
        // note={enterprise.transactions[trxIdx].note}
        // date={enterprise.transactions[trxIdx].date}
      />
    </>
  );
};

const InfoCard = ({ LogoIcon, title, num, secNum }) => {
  return (
    <>
      <Avatar
        sx={{
          backgroundColor: "#FAFBFC",
          width: "68px",
          height: "68px",
          borderColor: "#FFFFFFFF",
        }}
      >
        <LogoIcon htmlColor="#4B4749" sx={{ width: "30px", height: "30px" }} />
      </Avatar>
      <Typography
        variant="body1"
        sx={{
          mt: 1,
          height: "18px",
          alignItems: "flex-end",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="#3D61B0"
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          height: "20px",
          lineHeight: "20px",
          alignItems: "flex-end",
        }}
      >
        {num.toLocaleString(undefined)}
        {secNum && (
          <span
            style={{
              marginLeft: "5px",
              color: "#42B03D",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
            }}
          >
            {secNum.toFixed(2)}%
          </span>
        )}
      </Typography>
    </>
  );
};

const InfoButton = ({ BtnIcon, BtnText, onClick }) => {
  return (
    <Button
      onClick={() => {
        onClick();
      }}
      startIcon={<BtnIcon />}
      fullWidth
      sx={{
        backgroundColor: "#3D61B014",
        color: "#3D61B0",
        fontSize: "12px",
        py: 1,
        mt: 0,
        ":hover": { backgroundColor: "#3D61B024" },
      }}
    >
      {BtnText}
    </Button>
  );
};

const Section = ({ children, title, seeAll, addNew, sx }) => {
  return (
    <Grid2 sx={{ background: "#ffffff", borderRadius: "8px", p: 2, mt: 3 }}>
      <Typography variant="h5">
        {title}
        {addNew && (
          <IconButton
            onClick={() => {
              addNew();
            }}
            sx={{
              backgroundColor: "#FFFFFFFF",
              border: "0",
              ":hover": {
                backgroundColor: "#FFFFFFFF",
                border: "0",
              },
              color: "#3D61B0",
              p: 0,
            }}
          >
            <Add htmlColor="#3D61B0" />
          </IconButton>
        )}
        <Button
          variant="outlined"
          endIcon={<ChevronRight htmlColor="#3D61B0" />}
          onClick={() => {
            seeAll();
          }}
          sx={{
            backgroundColor: "#FFFFFFFF",
            border: "0",
            ":hover": {
              backgroundColor: "#FFFFFFFF",
              border: "0",
            },
            color: "#3D61B0",
            p: 0,
            float: "right",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "17px",
          }}
        >
          See all
        </Button>
      </Typography>
      <Divider sx={{ mt: 1 }} />
      <Container sx={{ m: 0, p: 0, ...sx }}>{children}</Container>
    </Grid2>
  );
};

export const TransactionList = ({ transactions, onClickItem }) => {
  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  return (
    <>
      {transactions.map((trx, i) => {
        return (
          <React.Fragment key={i}>
            <ListItemButton
              key={i}
              component="div"
              sx={{
                px: 0,
                pt: 1,
                pb: transactions.length - 1 === i ? 0 : 1,
                width: "100%",
              }}
              onClick={() => {
                onClickItem(i);
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ width: "40px", height: "40px" }}>
                  <CreditCard htmlColor="#4B4749" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={trx.type}
                secondary={trx.category}
                primaryTypographyProps={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#241F21",
                  textAlign: "left",
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#4B4749",
                  textAlign: "left",
                }}
                sx={
                  {
                    // display: "block",
                    // width: "100%"
                  }
                }
              />
              <ListItemText
                primary={`${trx.amount.toLocaleString(undefined)} ${(() => {
                  switch (trx.asset) {
                    case "ETH":
                      return "eth";
                    case "TOKEN":
                      return enterprise.info.tokenName;
                    case "USDC":
                      return "$";
                    default:
                      return "";
                  }
                })()}`}
                secondary={trx.date.toLocaleString("en-us", {
                  day: "numeric",
                  month: "short",
                  year: "2-digit",
                })}
                primaryTypographyProps={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "17px",
                  textAlign: "right",
                  color: "#4B4749",
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "12px",
                  textAlign: "right",
                  color: "#4B4749",
                }}
              />
            </ListItemButton>
            {transactions.length - 1 !== i && <Divider key={`divider${i}`} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DashboardPage;
