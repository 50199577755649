/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useState } from "react";
import { QrCode, SyncAlt } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { CreateAppBar } from "../../components/Appbar";
import { QRScanModal } from "../../components/dialog";
import { Input, Select } from "../../components/input";
import store from "../../store/store";
import {
  STORE_KEYS,
  isTxType,
  isTxCategory,
  TxTypes,
  TxCategories,
} from "../../store/constant";
import { useNavigate, useLocation } from "react-router-dom";

const Send = () => {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;

  const location = useLocation();
  const state = location.state;
  const { wepID } = state;

  const navigation = useNavigate();

  const [toAddr, setToAddr] = useState("9f24ac3f8ad5f0738ad5f5e0e441");
  const [amount, setAmount] = useState("");
  // price in USD (token to USD)
  const [price] = useState(1.25);
  const [balance] = useState(8350);
  const [moneyUnit] = useState("USDC");
  const [moneyLst] = useState(["USDC"]);
  const [type, setType] = useState("Money Transfer");
  const [categori, setCategory] = useState("None");
  const [note, setNote] = useState("");
  const [qrScanOpen, setQrScanOpen] = useState(false);

  const handleInputChange = (typee) => (evt) => {
    let value = evt.currentTarget.value;
    switch (typee) {
      case "walletAddr":
        setToAddr(value);
        break;
      case "amount":
        let regExp = /[0-9]+/;
        switch (type) {
          case "Money Transfer":
            regExp = /[0-9]*([.])?[0-9]?[0-9]?/;
            break;
          case "Share Transfer":
            regExp = /[0-9]+/;
            break;
          default:
            break;
        }

        if (regExp.exec(value)?.[0] === value || value === "") {
          console.log(value, value[0] === "0" && value[1] !== ".");
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;

          setAmount(value);
        }

        break;
      case "note":
        setNote(value);
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const value = evt.target.value;
    switch (type) {
      case "type":
        if (isTxType(value)) {
          setType(value);
          if (value === "Share Transfer") setAmount("");
        }
        break;
      case "moneyType":
        break;

      case "category":
        if (isTxCategory(value)) {
          setCategory(value);
        }
        break;

      default:
        break;
    }
  };

  const handleClickShowQR = () => {
    setQrScanOpen(true);
  };

  const closeQRScaner = () => {
    setQrScanOpen(false);
  };

  const qrHandler = (addr) => {
    setToAddr(addr);
  };

  const closeHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };

  const transferHandler = () => {
    let asset = "TOKEN";
    let category = "None";
    let notee = "";
    switch (type) {
      case "Money Transfer":
        asset = "USDC";
        category = categori;
        notee = note;
        break;
      case "Share Transfer":
        asset = "TOKEN";
        category = "None";
        notee = "";
        break;
      default:
        break;
    }
    const transaction = {
      id: Date.now(),
      asset: asset,
      type: type,
      category: category,
      amount: Number(amount),
      date: new Date(),
      note: notee,
      isSend: false,
      toAddress: toAddr,
    };
    updateEnterprises((prev) => {
      prev.tempEnterprise.transactions = [
        ...prev.tempEnterprise.transactions,
        transaction,
      ];
      return prev;
    });
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };

  return (
    <>
      <CreateAppBar title={type} close={closeHandler} helpEnabled={false} />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{ px: 3, mt: 5 }}
      >
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="type">
            Type
          </InputLabel>
          <Select
            id="type"
            value={type}
            onChange={handleSelectChange("type")}
            sx={{
              mt: 1,
              backgroundColor: "#3D61B0",
              color: "#FFFFFF",
              "& .MuiSvgIcon-root": {
                color: "#FFFFFF",
              },
            }}
          >
            {TxTypes.map((txtype, key) => {
              return (
                <MenuItem key={key} value={txtype}>
                  {txtype}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        {type === "Money Transfer" && (
          <Grid item xs={12} mt={2}>
            <InputLabel shrink htmlFor="moneyType">
              Money Type
            </InputLabel>
            <Select
              id="moneyType"
              value={moneyUnit}
              onChange={() => {
                handleSelectChange("moneyType");
              }}
              sx={{
                mt: 1,
                backgroundColor: "#3D61B0",
                color: "#FFFFFF",
                "& .MuiSvgIcon-root": {
                  color: "#FFFFFF",
                },
              }}
            >
              {moneyLst.map((moneyType, idx) => {
                return (
                  <MenuItem key={idx} value={moneyType}>
                    {moneyType}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        )}
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="sendTo">
            Send to
          </InputLabel>
          <Input
            id="sendTo"
            value={toAddr}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("walletAddr")}
            endAdornment={
              <IconButton onClick={handleClickShowQR} edge="end">
                <QrCode htmlColor="#3D61B0" />
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="amount">
            {type === "Share Transfer" && "Number of Shares"}
            {type === "Money Transfer" && "Amount"}
          </InputLabel>
          <Container
            sx={{
              display: "flex",
              p: 0,
              mt: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              id="amount"
              fullWidth
              onChange={handleInputChange("amount")}
              value={amount}
              placeholder={type === "Share Transfer" ? "0" : "0.00"}
              sx={{
                "& input": {
                  textAlign: "right",
                },
              }}
            />
            <SyncAlt htmlColor="#3D61B0" />
            <Input
              id="amount1"
              fullWidth
              disabled
              value={(Number(amount) * price).toFixed(2)}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              sx={{
                "& input": {
                  textAlign: "right",
                },
              }}
            />
          </Container>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "18px",
              textAlign: "center",
            }}
          >
            Balance: {balance - Number(amount)}{" "}
            {type === "Money Transfer" && moneyUnit}
            {type === "Share Transfer" && enterprise.info.tokenName}
          </Typography>
        </Grid>
        {type === "Money Transfer" && (
          <>
            <Grid item xs={12} mt={2}>
              <InputLabel shrink htmlFor="type">
                Category (optional)
              </InputLabel>
              <Select
                id="category"
                value={categori}
                onChange={handleSelectChange("category")}
                fullWidth
              >
                {TxCategories.map((cate, idx) => {
                  return (
                    <MenuItem key={idx} value={cate}>
                      {cate}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12} mt={2}>
              <InputLabel shrink htmlFor="note">
                Payment Note (optional)
              </InputLabel>
              <Input
                id="note"
                value={note}
                fullWidth
                sx={{ mt: 1 }}
                multiline
                rows={4}
                onChange={handleInputChange("note")}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
          <Button fullWidth onClick={transferHandler}>
            Transfer
          </Button>
        </Grid>
        {type === "Share Transfer" && (
          <Grid item xs={12} mt={2}>
            <Typography
              variant="body1"
              px={5}
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Your shareholding will reduce to {"X"}% after this transfer is
              approved
            </Typography>
          </Grid>
        )}
      </Grid>
      <QRScanModal
        open={qrScanOpen}
        onClose={closeQRScaner}
        qrHandler={qrHandler}
      />
    </>
  );
};

export default Send;
