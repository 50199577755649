/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState } from "react";
import { Download } from "@mui/icons-material";
import {
  AppBar,
  Box,
  IconButton,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import SwipeableViews from "react-swipeable-views";
import { ProfileAppBar } from "../../components/Appbar";
import { Alert, TransactionDetailModal } from "../../components/dialog";
import { Select } from "../../components/input";
import { a11yProps, TabPanel } from "../../components/Tab";
import { downloadFile } from "../../utils/downloadFile";
import { TransactionList } from "./dashboard";
import store from "../../store/store";
import { STORE_KEYS, TxCategories, TxAssets } from "../../store/constant";
import { useLocation, useNavigate } from "react-router-dom";

const SearchCategories = ["All", ...TxCategories];
const isSearchCategory = (val) => SearchCategories.includes(val);

const SearchAssets = ["All", ...TxAssets];
const isSearchAsset = (val) => SearchAssets.includes(val);

const Transactions = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { wepID } = state;

  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = useState("All");
  const [asset, setAsset] = useState("All");
  const [alert, setAlert] = useState({
    opened: false,
    severity: "success",
    content: "",
    time: 6000,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSelectChange = (type) => (evt) => {
    const temp = evt.target.value;
    switch (type) {
      case "asset":
        if (isSearchAsset(temp)) setAsset(temp);
        break;
      case "category":
        if (isSearchCategory(temp)) setCategory(temp);
        break;
      default:
        break;
    }
  };

  const handleAlertOpen = (severity, content, time) => {
    setAlert({
      opened: true,
      severity,
      content,
      time,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert({
      opened: false,
      severity: "success",
      content: "",
      time: 6000,
    });
  };

  const [logo, setLogo] = useState("/images/ABC_Logo1.png");
  const [name, setName] = useState("ABC Corporation");
  const [ID, setID] = useState("WEp 1452");
  const [transactions, setTrnasactions] = useState([]);

  useEffect(() => {
    setLogo(enterprise.info.logo);
    setName(enterprise.info.name);
    setID(enterprise.info.wepID || "");
    setTrnasactions(enterprise.transactions);
  }, [enterprise]);

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };

  // Tramsaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const trxClickHandler = (idx) => {
    setTrxDlgOpen(true);
    setTrxIdx(idx);
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let data = transactions;
    if (category !== "All")
      data = transactions.filter((trx) => trx.category === category);
    if (asset !== "All") data = data.filter((trx) => trx.asset === asset);
    switch (value) {
      case 0:
        break;
      case 1:
        data = data.filter((trx) => trx.isSend);
        break;
      case 2:
        data = data.filter((trx) => !trx.isSend);
        break;
      default:
        break;
    }

    let headers = ["id,asset,type,category,amount,note,date,isSend,toAddress"];
    let filename = "";
    switch (value) {
      case 0:
        filename = "transactions.csv";
        break;
      case 1:
        filename = "transactions_sent.csv";
        break;
      case 2:
        filename = "transactions_received.csv";
        break;
      default:
        filename = "transactions.csv";
    }

    // // Headers for each column
    // let headers = ['Id,Name,Surname,Age']

    // Convert users data to a csv
    let usersCsv = data.reduce((acc, trx) => {
      const {
        id,
        asset,
        type,
        category,
        amount,
        note,
        date,
        isSend,
        toAddress,
      } = trx;
      acc.push(
        [id, asset, type, category, amount, note, date, isSend, toAddress].join(
          ","
        )
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: filename,
      fileType: "text/csv",
    });

    handleAlertOpen("success", "CSV file was exported!", 6000);
  };

  return (
    <>
      <Box sx={{ bgcolor: "#FFFFFF", width: "100%" }}>
        <AppBar
          position="absolute"
          sx={{
            color: "#241F21",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",
          }}
        >
          <ProfileAppBar
            logo={logo}
            name={name}
            ID={ID}
            back={back}
            secondComponent={
              <IconButton onClick={exportToCsv}>
                <Download />
              </IconButton>
            }
          />
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              mt: -6,
              backgroundColor: "#FFDB0A",
              "& .MuiTabs-indicator": {
                backgroundColor: "#241F21",
              },
            }}
          >
            <Tab
              label="All"
              {...a11yProps(0)}
              sx={{ p: 0, minWidth: 0, textTransform: "none" }}
            />
            <Tab
              label="Sent"
              {...a11yProps(1)}
              sx={{ p: 0, minWidth: 0, textTransform: "none" }}
            />
            <Tab
              label="Received"
              {...a11yProps(2)}
              sx={{ p: 0, minWidth: 0, textTransform: "none" }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{ height: "100vh" }}
        >
          {[0, 1, 2].map((index, key) => (
            <TabPanel
              key={key}
              value={value}
              index={index}
              dir={theme.direction}
            >
              <Box sx={{ mt: 27, p: 0 }}>
                <Box
                  sx={{
                    px: 0,
                    py: 0,
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <Select
                    id="category"
                    value={category}
                    onChange={() => {
                      handleSelectChange("category");
                    }}
                    fullWidth
                  >
                    {SearchCategories.map((cate, idx) => {
                      return (
                        <MenuItem key={idx} value={cate}>
                          {cate === "All" ? "All Category" : cate}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    id="asset"
                    value={asset}
                    onChange={() => {
                      handleSelectChange("asset");
                    }}
                    fullWidth
                  >
                    {SearchAssets.map((ast, idx) => {
                      //possible solution is to remove what is after colon
                      return (
                        <MenuItem key={idx} value={ast}>
                          {(() => {
                            switch (ast) {
                              case "All":
                                return "All Assets";
                              case "TOKEN":
                                return enterprise.info.tokenName;
                              default:
                                return ast;
                            }
                          })()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
                <Container sx={{ p: 0 }}>
                  <TransactionList
                    transactions={(() => {
                      let temp = transactions;
                      if (category !== "All")
                        temp = transactions.filter(
                          (trx) => trx.category === category
                        );
                      if (asset !== "All")
                        temp = temp.filter((trx) => trx.asset === asset);
                      switch (index) {
                        case 0:
                          return temp;
                        case 1:
                          return temp.filter((trx) => trx.isSend);
                        case 2:
                          return temp.filter((trx) => !trx.isSend);
                        default:
                          return temp;
                      }
                    })()}
                    onClickItem={trxClickHandler}
                  />
                </Container>
              </Box>
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>

      <Snackbar
        open={alert.opened}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.content}
        </Alert>
      </Snackbar>
      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
      />
    </>
  );
};

export default Transactions;
