/**
 * @author Puffer
 * @createdAt 12/19/2022
 * @updatedAt 12/19/2022
 **/

import React, { useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Container,
  Toolbar,
  Link,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import detectEthereumProvider from "@metamask/detect-provider";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../utils/connectors";
import { mobileCheck } from "../../utils/mobileCheck";
import SelectWalletModal from "../dialog/SelectWalletModal";
import store from "../../store/store";
import { STORE_KEYS, PAGE_NAME } from "../../store/constant";

import SMSModal from "../sms/modal";

const pages = [PAGE_NAME.id.home, PAGE_NAME.id.aboutUs];
const landingTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#0D141E",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};
const slideSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const phoneVerifyStatus = {
  INIT: "INIT",
  SHOW: "SHOW",
  HIDE: "HIDE",
};

const Landing = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { activate } = useWeb3React();
  const [provider, setProvider] = React.useState(null);
  const [phoneVerifyModal, setPhoneVerifyModal] = React.useState(
    phoneVerifyStatus.INIT
  );
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const connectHandler = () => {
    if (!mobileCheck() && phoneVerifyModal === phoneVerifyStatus.INIT) {
      return setPhoneVerifyModal(phoneVerifyStatus.SHOW);
    }

    if (mobileCheck() && provider) {
      const connector = connectors.injected;
      activate(connector);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    detectEthereumProvider().then((provider) => {
      console.log("Provider detection finished", provider);
      setProvider(provider);
    });
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navItemHandle = (page) => {
    updateEnterprises((prev) => {
      prev.pageName = page;
      return prev;
    });
    handleCloseNavMenu();
  };

  return (
    <Box
      sx={{
        bgcolor: landingTheme.primary,
        width: 1,
        pt: "50px",
      }}
    >
      <Container>
        <AppBar
          position="relative"
          color="transparent"
          sx={{
            boxShadow: "none",
            mb: "39px",
          }}
        >
          <Toolbar
            sx={{
              padding: "0 !important",
            }}
          >
            <Box
              component="img"
              src="/images/logo.svg"
              alt=""
              sx={{
                width: {
                  xs: "121px",
                  md: "auto",
                },
              }}
            />
            <Box sx={{ ml: "auto" }}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Link
                    // href="#"
                    underline="none"
                    key={page}
                    sx={{
                      my: 2,
                      color: `${landingTheme.black} !important`,
                      lineHeight: {
                        md: "28.88px",
                      },
                      mr: "59px",
                      display: "block",
                      width: "fit-content",
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navItemHandle(page);
                    }}
                  >
                    {page}
                  </Link>
                ))}
                {/* <Button
                  variant="outlined"
                  sx={{
                    p: { md: "10px 51px" },
                    borderColor: landingTheme.black,
                    display: { xs: "none", md: "flex" },
                    fontSize: landingTheme.fontSize,
                    lineHeight: landingTheme.lineHeight,
                    color: landingTheme.black,
                    backgroundColor: "transparent",
                    fontWeight: 600,
                  }}
                  onClick={connectHandler}
                >
                  Connect
                </Button> */}
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{
                    p: "0",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => {
                        navItemHandle(page);
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Container>

      <SMSModal
        open={phoneVerifyModal === phoneVerifyStatus.SHOW}
        handleClose={() => {
          setPhoneVerifyModal(phoneVerifyStatus.HIDE);
          connectHandler();
        }}
      />

      {/* world enterprises? */}
      <Box
        sx={{
          backgroundImage: { xs: "none", md: "url(/images/earth.svg)" },
          backgroundSize: "50% 100%",
          backgroundPosition: "right top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          sx={{
            textAlign: { xs: "center", md: "left" },
            py: { xs: "40px", md: "140px" },
          }}
        >
          <Box sx={{ width: "fit-content" }}>
            <Box
              component="img"
              src="/images/World_Enterprise.svg"
              alt=""
              sx={{
                mb: { xs: "35px", md: "45px" },
                maxWidth: "100%",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: landingTheme.fontSize,
              lineHeight: landingTheme.lineHeight,
              fontWeight: "300",
              color: landingTheme.black,
              mb: { xs: "20px", md: "40px" },
              width: { xs: "auto", md: "605px" },
            }}
          >
            World Enterprise is a platform for entrepreneurs and companies to
            launch and manage enterprises on the blockchain.
          </Typography>
          <Button
            sx={{
              background: "black",
              padding: { xs: "11px 57px", md: "10px 63px" },
              color: "white",
              fontSize: landingTheme.fontSize,
              fontWeight: "500",
              lineHeight: "180.5%",
              borderRadius: "8px",
              mb: "20px",
            }}
            onClick={connectHandler}
          >
            Get Started
          </Button>
          <br />
          {/* <Button
            variant="outlined"
            sx={{
              display: { xs: "inline-flex", md: "none" },
              p: "11px 67px",
              borderColor: landingTheme.black,
              fontSize: landingTheme.fontSize,
              lineHeight: "180.5%",
              color: landingTheme.black,
              backgroundColor: "transparent",
              fontWeight: 600,
              borderRadius: "8px",
              mb: "38px",
            }}
            onClick={connectHandler}
          >
            Connect
          </Button> */}
        </Container>
        <Box
          component="img"
          src="/images/earth.svg"
          alt=""
          sx={{
            display: { xs: "block", md: "none" },
            width: "95%",
            ml: "5%",
          }}
        />
      </Box>

      {/* what is world enterprises? */}
      <Box
        sx={{
          position: "relative",
          pt: { xs: "45px", md: "137px" },
          background: "white",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box
          component="img"
          src="/images/home_section2_top.svg"
          alt=""
          sx={{
            position: "absolute",
            top: { xs: "159px", md: "84px" },
            left: { xs: "80%", md: "50%" },
            width: { xs: "28px", md: "auto" },
          }}
        />
        <Box
          component="img"
          src="/images/home_section2_right.svg"
          alt=""
          sx={{
            position: "absolute",
            right: "0",
            top: { xs: "386px", md: "172px" },
            height: { xs: "54px", md: "auto" },
          }}
        />
        <Box
          component="img"
          src="/images/home_section2_bottom.svg"
          alt=""
          sx={{
            position: "absolute",
            right: "0",
            top: { xs: "386px", md: "564px" },
            left: { xs: "19px", md: "70%" },
            width: { xs: "48px", md: "auto" },
          }}
        />
        <Container
          sx={{
            position: "relative",
            zIndex: "2",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography
                sx={{
                  display: { xs: "block", md: "none" },
                  fontSize: "23px",
                  lineHeight: "28px",
                  mb: "50px",
                  fontWeight: "700",
                }}
              >
                What is World Enterprise?
              </Typography>
              <Box
                component="img"
                src="/images/home_section2_left.png"
                alt=""
                sx={{
                  width: { xs: "80%", md: "100%" },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                mt: { xs: "35px", md: "145px" },
              }}
            >
              <Typography
                sx={{
                  display: { xs: "none", md: "block" },
                  fontSize: "44px",
                  lineHeight: "54px",
                  mb: "30px",
                  fontWeight: "700",
                }}
              >
                What is World Enterprise?
              </Typography>
              <Typography
                sx={{
                  fontSize: landingTheme.fontSize,
                  lineHeight: landingTheme.lineHeight,
                  mb: { xs: "20px", md: "39px" },
                }}
              >
                World Enterprise is a platform for entrepreneurs and companies
                to launch and manage enterprises on the blockchain. World
                Enterprise provides you the tools to create, manage, and
                participate in decentralized organizations (World Enterprises)
                that are governed by smart contracts on the blockchain.
              </Typography>
              <Button
                sx={{
                  fontSize: landingTheme.fontSize,
                  lineHeight: landingTheme.lineHeight,
                  padding: "10px 49px",
                  color: "white",
                  borderRadius: "8px",
                }}
                onClick={connectHandler}
              >
                Launch App
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* How it works */}
      <Box
        sx={{
          pt: { xs: "90px", md: "11px" },
          pb: { xs: "90px", md: "130px" },
          background: "white",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: { xs: "30px", md: "50px" },
              lineHeight: { xs: "37px", md: "61px" },
              mb: { xs: "35px", md: "78px" },
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            How it works
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={7}
              sx={
                {
                  // mt: { xs: "35px", md: "78px" },
                }
              }
            >
              <Typography
                sx={{
                  fontSize: { xs: "25px", md: "44px" },
                  lineHeight: { xs: "30px", md: "54px" },
                  mb: { xs: "20px", md: "30px" },
                  fontWeight: "700",
                }}
              >
                Create WE
              </Typography>
              <Typography
                sx={{
                  fontSize: landingTheme.fontSize,
                  lineHeight: { xs: "17px", md: "22px" },
                  mb: { xs: "35px", md: "25px" },
                }}
              >
                Whether you have a new company or want to migrate your existing
                company to the blockchain, WE gives you the ability to create
                your own decentralized enterprise. Every WE has its own ERC20
                token to represent shares and membership in the WE. During the
                setup process, you add the initial token supply for the WE, who
                the initial shareholders will be, and any Admins that you'd like
                to manage the day-to-day operations of the enterprise. After the
                WE is deployed on the blockchain, shareholders can begin
                creating and voting on proposals, and Admins can begin
                collecting revenue and paying expenses.
              </Typography>

              {/* Create process: */}
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "23px",
                    lineHeight: "180.5%",
                    mb: "22px",
                    fontWeight: "700",
                  }}
                >
                  Create process:
                </Typography>

                <Box
                  sx={{
                    width: { xs: "100%", md: "80%" },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "22px",
                            mb: { xs: "20px", md: "25px" },
                          }}
                        >
                          Enter WE details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "22px",
                            mb: { xs: "20px", md: "25px" },
                          }}
                        >
                          Add shareholders and initial token supply
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "22px",
                            mb: { xs: "20px", md: "25px" },
                          }}
                        >
                          Add admins
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "22px",
                            mb: { xs: "20px", md: "25px" },
                          }}
                        >
                          Create on the blockchain!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                component="img"
                src="/images/how_it_works_right.png"
                alt=""
                sx={{
                  width: "100%",
                }}
              />

              {/* Create process: */}
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "23px",
                    lineHeight: "180.5%",
                    mb: "16px",
                    fontWeight: "700",
                  }}
                >
                  Create process:
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: "0 !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "17px",
                            mb: "15px",
                          }}
                        >
                          Enter WE details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: "0 !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "17px",
                            mb: "15px",
                          }}
                        >
                          Add shareholders and initial token supply
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: "0 !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "17px",
                            mb: "15px",
                          }}
                        >
                          Add admins
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: "0 !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: "15px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: landingTheme.fontSize,
                            lineHeight: "17px",
                            mb: "15px",
                          }}
                        >
                          Create on the blockchain!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Join WE */}
      <Box
        sx={{
          pt: { xs: "36px", md: "55px" },
          pb: { xs: "36px", md: "56px" },
          background: "#F4F8FF",
          textAlign: { xs: "center", md: "left" },
          position: "relative",
        }}
      >
        <Box
          component="img"
          src="/images/home_section3_left2.svg"
          alt=""
          sx={{
            position: "absolute",
            bottom: { xs: "450px", md: "0" },
            left: 0,
            width: { xs: "60%", md: "auto" },
          }}
        />
        <Container
          sx={{
            position: "relative",
            zIndex: "1",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", md: "44px" },
              lineHeight: { xs: "30px", md: "54px" },
              mb: { xs: "20px", md: "27px" },
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Join WE
          </Typography>
          <Typography
            sx={{
              fontSize: landingTheme.fontSize,
              lineHeight: { xs: "17px", md: "22px" },
              mb: { xs: "70px", md: "42px" },
              textAlign: "center",
              color: landingTheme.black,
            }}
          >
            If you’re not already a member of a World Enterprise, you can browse
            all existing World Enterprises and request to join any that you’d
            like to be a member of. You can request to join any WE for free or
            offer to buy shares from the treasury of the WE.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                component="img"
                src="/images/home_section3_left.svg"
                alt=""
                sx={{
                  width: "80%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                mt: { xs: "65.5px", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", md: "32px" },
                  lineHeight: { xs: "24px", md: "39px" },
                  mb: { xs: "20px", md: "17px" },
                  fontWeight: "700",
                }}
              >
                Join Process
              </Typography>

              {/* box1 */}
              <Box
                sx={{
                  position: "relative",
                  mb: { xs: "8px", md: "10px" },
                  pt: { xs: "16px", md: "24px" },
                  pl: { xs: "16px", md: "24px" },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: { xs: "32px", md: "47px" },
                    borderRadius: "100%",
                    background: landingTheme.primary,
                    color: "black",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "32px", md: "47px" },
                    textAlign: "center",
                    fontWeight: "700",
                    zIndex: "1",
                  }}
                >
                  1
                </Box>
                <Button
                  sx={{
                    background: landingTheme.secondary,
                    color: "white",
                    padding: { xs: "14px 34.5px", md: "21px, 49px" },
                    width: "100%",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "17px", md: "22px" },
                  }}
                >
                  Browse World Enterprises
                </Button>
              </Box>

              {/* box2 */}
              <Box
                sx={{
                  position: "relative",
                  mb: { xs: "8px", md: "10px" },
                  pt: { xs: "16px", md: "24px" },
                  pl: { xs: "16px", md: "24px" },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: { xs: "32px", md: "47px" },
                    borderRadius: "100%",
                    background: landingTheme.primary,
                    color: "black",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "32px", md: "47px" },
                    textAlign: "center",
                    fontWeight: "700",
                    zIndex: "1",
                  }}
                >
                  2
                </Box>
                <Button
                  sx={{
                    background: landingTheme.secondary,
                    color: "white",
                    padding: { xs: "14px 34.5px", md: "21px, 49px" },
                    width: "100%",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "17px", md: "22px" },
                    mb: { xs: "8px", md: "11px" },
                  }}
                >
                  Request to join
                </Button>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Button
                    sx={{
                      background: landingTheme.secondary,
                      color: "white",
                      padding: { xs: "14px 34.5px", md: "14px, 49px" },
                      width: "45%",
                      mr: "10%",
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                    }}
                  >
                    For free
                  </Button>
                  <Button
                    sx={{
                      background: landingTheme.secondary,
                      color: "white",
                      padding: { xs: "14px 34.5px", md: "14px, 49px" },
                      width: "45%",
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                    }}
                  >
                    Buy shares
                  </Button>
                </Box>
              </Box>

              {/* box3 */}
              <Box
                sx={{
                  position: "relative",
                  mb: { xs: "8px", md: "10px" },
                  pt: { xs: "16px", md: "24px" },
                  pl: { xs: "16px", md: "24px" },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: { xs: "32px", md: "47px" },
                    borderRadius: "100%",
                    background: landingTheme.primary,
                    color: "black",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "32px", md: "47px" },
                    textAlign: "center",
                    fontWeight: "700",
                    zIndex: "1",
                  }}
                >
                  3
                </Box>
                <Button
                  sx={{
                    background: landingTheme.secondary,
                    color: "white",
                    padding: { xs: "14px 34.5px", md: "21px, 49px" },
                    width: "100%",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "17px", md: "22px" },
                  }}
                >
                  Proposal is created for the WE where shareholders will approve
                  or reject your request.
                </Button>
              </Box>

              {/* box4 */}
              <Box
                sx={{
                  position: "relative",
                  mb: { xs: "8px", md: "10px" },
                  pt: { xs: "16px", md: "24px" },
                  pl: { xs: "16px", md: "24px" },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: { xs: "32px", md: "47px" },
                    borderRadius: "100%",
                    background: landingTheme.primary,
                    color: "black",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "32px", md: "47px" },
                    textAlign: "center",
                    fontWeight: "700",
                    zIndex: "1",
                  }}
                >
                  4
                </Box>
                <Button
                  sx={{
                    background: landingTheme.secondary,
                    color: "white",
                    padding: { xs: "14px 34.5px", md: "21px, 49px" },
                    width: "100%",
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "17px", md: "22px" },
                  }}
                >
                  You’ll receive share
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* WE Components what makes a WE? */}
      <Box
        sx={{
          pt: { xs: "36px", md: "90px" },
          pb: { xs: "100px", md: "200px" },
          background: "white",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src="/images/what_make_we_6.svg"
          alt=""
          sx={{
            position: "absolute",
            top: { xs: "0", md: "266px" },
            right: { xs: "0", md: "57px" },
            width: { xs: "53px", md: "auto" },
            zIndex: "2",
          }}
        />
        <Box
          component="img"
          src="/images/what_make_we_7.svg"
          alt=""
          sx={{
            position: "absolute",
            bottom: { xs: "32px", md: "62px" },
            width: { xs: "38px", md: "auto" },
            left: "0",
          }}
        />
        <Container
          sx={{
            position: "relative",
            zIndex: "1",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", md: "44px" },
              lineHeight: { xs: "30px", md: "54px" },
              mb: { xs: "20px", md: "118px" },
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            WE Components <br />
            what makes a WE?
          </Typography>
          <Box
            sx={{
              display: { xs: "none", md: "grid" },
              gridTemplateColumns: "2fr 1fr",
              gridAutoRows: "1fr",
              gridColumnGap: "70px",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "repeat(2, 1fr)",
                  gridAutoRows: "1fr",
                  gridColumnGap: "53px",
                  gridRowGap: "24px",
                }}
              >
                <Box
                  sx={{
                    p: { xs: "", md: "40px 35px 35px" },
                    borderRadius: "8px",
                    background:
                      "linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      mb: { xs: "35px", md: "30px" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_1.png"
                      alt=""
                      sx={{
                        mr: "15px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: "17px", md: "22px" },
                        fontWeight: "600",
                      }}
                    >
                      WE ERC20 tokens
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "17px", md: "16px" },
                      lineHeight: { xs: "17px", md: "20px" },
                    }}
                  >
                    Every WE has its own ERC20 token that represents shares and
                    membership in the enterprise. Anyone who owns the ERC20
                    token of the WE is considered a member and can create new
                    proposals and vote on proposals from other members. Token
                    holders can come together and decide to issue new tokens and
                    change the supply and tokenomics over time
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: "", md: "40px 35px 35px" },
                    borderRadius: "8px",
                    background: "white",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25);",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      mb: { xs: "35px", md: "30px" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_3.svg"
                      alt=""
                      sx={{
                        mr: "15px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: "17px", md: "22px" },
                        fontWeight: "600",
                      }}
                    >
                      Proposals
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "17px", md: "16px" },
                      lineHeight: { xs: "17px", md: "20px" },
                    }}
                  >
                    Any idea suggested to improve the World Enterprise is a
                    proposal. Shareholders and Admins can submit proposals, but
                    only shareholders can approve the proposal. All proposals
                    are open for voting, and a proposal needs majority approval
                    before it can be automatically executed on the blockchain.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: "", md: "40px 35px 35px" },
                    borderRadius: "8px",
                    background: "white",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25);",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      mb: { xs: "35px", md: "30px" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_2.svg"
                      alt=""
                      sx={{
                        mr: "15px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: "17px", md: "22px" },
                        fontWeight: "600",
                      }}
                    >
                      Transactions
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "17px", md: "16px" },
                      lineHeight: { xs: "17px", md: "20px" },
                    }}
                  >
                    Every WE has a public and transparent ledger for incoming
                    and outgoing transactions of all digital assets on the
                    blockchain including account tags and notes on what each
                    transaction was for. Admins are responsible for executing
                    transactions on behalf of the WE, and anyone can export
                    transactions for accounting purposes.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: "", md: "40px 35px 35px" },
                    borderRadius: "8px",
                    background:
                      "linear-gradient(229.1deg, #3F69FF -67.11%, rgba(255, 255, 255, 0) 143.43%);",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      mb: { xs: "35px", md: "30px" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_4.svg"
                      alt=""
                      sx={{
                        mr: "15px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: "17px", md: "22px" },
                        fontWeight: "600",
                      }}
                    >
                      Admins
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "17px", md: "16px" },
                      lineHeight: { xs: "17px", md: "20px" },
                    }}
                  >
                    {" "}
                    An Admin is anyone approved by shareholders to manage the
                    daily operations of the World Enterprise. Admins have access
                    to the treasury and can send money and issue invoices on
                    behalf of the enterprise.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                p: { xs: "", md: "40px 35px 35px" },
                borderRadius: "8px",
                background:
                  "linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  mb: { xs: "35px", md: "30px" },
                  mt: { xs: "170px", md: "170px" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  src="/images/what_make_we_5.svg"
                  alt=""
                  sx={{
                    mr: "15px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: "17px", md: "22px" },
                    fontWeight: "600",
                  }}
                >
                  Shareholders
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "17px", md: "16px" },
                  lineHeight: { xs: "17px", md: "20px" },
                }}
              >
                {" "}
                A Shareholder is anyone who owns a WE ERC20 token is considered
                a shareholder of that WE. By owning the WE’s shares (native
                token) and being a member, you can influence and help determine
                the future of any WE. Shareholders can create proposals and add
                new shareholders, create more ERC20 tokens, and
                add/remove/replace Admins. Shareholders can also approve and
                reject any new members who want to join the WE. Over time the
                variety of proposals will be expanded to include additional
                important corporate governance items including giving
                shareholders the right to vote on company strategy, company
                budgets, company investments, etc.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            <Slider {...slideSettings}>
              <Box
                sx={{
                  width: "90% !important",
                  p: { xs: "40px 0", md: "40px 35px 35px" },
                  mx: "5%",
                  mt: "10px",
                  borderRadius: { xs: "5px", md: "8px" },
                  background:
                    "linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: { xs: "35px", md: "30px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/what_make_we_1.png"
                    alt=""
                    sx={{
                      mr: "15px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                      fontWeight: "600",
                    }}
                  >
                    WE ERC20 tokens
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    px: "31px",
                    fontSize: { xs: "17px", md: "16px" },
                    lineHeight: { xs: "17px", md: "20px" },
                  }}
                >
                  Every WE has its own ERC20 token that represents shares and
                  membership in the enterprise. Anyone who owns the ERC20 token
                  of the WE is considered a member and can create new proposals
                  and vote on proposals from other members. Token holders can
                  come together and decide to issue new tokens and change the
                  supply and tokenomics over time
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "90% !important",
                  p: { xs: "40px 0", md: "40px 35px 35px" },
                  mx: "5%",
                  mt: "10px",
                  borderRadius: { xs: "5px", md: "8px" },
                  background: "white",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25);",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: { xs: "35px", md: "30px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/what_make_we_3.svg"
                    alt=""
                    sx={{
                      mr: "15px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                      fontWeight: "600",
                    }}
                  >
                    Proposals
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    px: "31px",
                    fontSize: { xs: "17px", md: "16px" },
                    lineHeight: { xs: "17px", md: "20px" },
                  }}
                >
                  Any idea suggested to improve the World Enterprise is a
                  proposal. Shareholders and Admins can submit proposals, but
                  only shareholders can approve the proposal. All proposals are
                  open for voting, and a proposal needs majority approval before
                  it can be automatically executed on the blockchain.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "90% !important",
                  p: { xs: "40px 0", md: "40px 35px 35px" },
                  mx: "5%",
                  mt: "10px",
                  borderRadius: { xs: "5px", md: "8px" },
                  background: "white",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25);",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: { xs: "35px", md: "30px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/what_make_we_2.svg"
                    alt=""
                    sx={{
                      mr: "15px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                      fontWeight: "600",
                    }}
                  >
                    Transactions
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    px: "31px",
                    fontSize: { xs: "17px", md: "16px" },
                    lineHeight: { xs: "17px", md: "20px" },
                  }}
                >
                  Every WE has a public and transparent ledger for incoming and
                  outgoing transactions of all digital assets on the blockchain
                  including account tags and notes on what each transaction was
                  for. Admins are responsible for executing transactions on
                  behalf of the WE, and anyone can export transactions for
                  accounting purposes.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "90% !important",
                  p: { xs: "40px 0", md: "40px 35px 35px" },
                  mx: "5%",
                  mt: "10px",
                  borderRadius: { xs: "5px", md: "8px" },
                  background:
                    "linear-gradient(229.1deg, #3F69FF -67.11%, rgba(255, 255, 255, 0) 143.43%);",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: { xs: "35px", md: "30px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/what_make_we_4.svg"
                    alt=""
                    sx={{
                      mr: "15px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                      fontWeight: "600",
                    }}
                  >
                    Admins
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    px: "31px",
                    fontSize: { xs: "17px", md: "16px" },
                    lineHeight: { xs: "17px", md: "20px" },
                  }}
                >
                  {" "}
                  An Admin is anyone approved by shareholders to manage the
                  daily operations of the World Enterprise. Admins have access
                  to the treasury and can send money and issue invoices on
                  behalf of the enterprise.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "90% !important",
                  p: { xs: "40px 0", md: "40px 35px 35px" },
                  mx: "5%",
                  mt: "10px",
                  borderRadius: { xs: "5px", md: "8px" },
                  maxHeight: "350px",
                  overflow: "scroll",
                  background:
                    "linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: { xs: "35px", md: "30px" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src="/images/what_make_we_5.svg"
                    alt=""
                    sx={{
                      mr: "15px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: landingTheme.fontSize,
                      lineHeight: { xs: "17px", md: "22px" },
                      fontWeight: "600",
                    }}
                  >
                    Shareholders
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    px: "31px",
                    fontSize: { xs: "17px", md: "16px" },
                    lineHeight: { xs: "17px", md: "20px" },
                  }}
                >
                  {" "}
                  A Shareholder is anyone who owns a WE ERC20 token is
                  considered a shareholder of that WE. By owning the WE’s shares
                  (native token) and being a member, you can influence and help
                  determine the future of any WE. Shareholders can create
                  proposals and add new shareholders, create more ERC20 tokens,
                  and add/remove/replace Admins. Shareholders can also approve
                  and reject any new members who want to join the WE. Over time
                  the variety of proposals will be expanded to include
                  additional important corporate governance items including
                  giving shareholders the right to vote on company strategy,
                  company budgets, company investments, etc.
                </Typography>
              </Box>
            </Slider>
          </Box>
        </Container>
      </Box>

      {/* footer */}
      <Box
        sx={{
          py: { xs: "37px", md: "58px" },
        }}
      >
        <Container>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              textAlign: { xs: "center", md: "left" },
              justifyContent: "space-between ",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/images/logo.svg"
              alt=""
              sx={{
                width: {
                  xs: "121px",
                  md: "auto",
                },
                mb: { xs: "20px", md: "0" },
              }}
            />
            <Box
              sx={{
                mb: { xs: "20px", md: "0" },
              }}
            >
              <Button
                sx={{
                  background: "transparent",
                  border: {
                    xs: "1.5px solid #000000",
                    md: "3px solid #000000",
                  },
                  width: { xs: "20px", md: "48px" },
                  height: { xs: "20px", md: "48px" },
                  mr: { xs: "17px", md: "40px" },
                  // p: {xs: "5px", md: "11px !important"},
                  minWidth: 0,
                }}
              >
                <Box
                  component="img"
                  src="/images/facebook.svg"
                  sx={{
                    height: { xs: "11px", md: "auto" },
                  }}
                />
              </Button>
              <Button
                sx={{
                  background: "transparent",
                  // border: "3px solid #000000",
                  border: {
                    xs: "1.5px solid #000000",
                    md: "3px solid #000000",
                  },
                  width: { xs: "20px", md: "48px" },
                  height: { xs: "20px", md: "48px" },
                  minWidth: 0,
                }}
              >
                <Box
                  component="img"
                  src="/images/twitter.svg"
                  sx={{
                    height: { xs: "11px", md: "auto" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                mb: { xs: "20px", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  lineHeight: { xs: "17px", md: "20px" },
                  fontWeight: "500",
                  color: "black",
                }}
              >
                ©2022 | All rights reserved by WE Enterprise
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </Box>
  );
};

export default Landing;
