/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { Add, ArrowForward } from "@mui/icons-material";
import { CreateAppBar } from "../../components/Appbar";
import { AdminAdd, AdminsList } from "../../components/admins";
import {
  ShareholderAdd,
  ShareholdersList,
} from "../../components/shareholders";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import {
  BtnCancel,
  BtnOption,
  DetailDialog,
  Transition,
} from "../../components/dialog";

const Members = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { webID } = state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const [category, setCategory] = useState("shareholders");
  const [newOpen, setNewOpen] = useState(false);
  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { webID } });
  };

  const switchHandler = () => {
    switch (category) {
      case "admins":
        setCategory("shareholders");
        break;
      case "shareholders":
        setCategory("admins");
        break;
      default:
        break;
    }
  };
  const newOpenClose = () => {
    setNewOpen(false);
  };

  const addHandler = () => {
    setNewOpen(true);
  };
  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
  };

  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT);
  };

  useEffect(() => {
    setStep(STEP.DASHBOARD_MEMBERS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header pageTitle={"Members"} />
      {stepState === STEP.DASHBOARD_MEMBERS && (
        <>
          <CreateAppBar
            title="Members"
            close={backHandler}
            helpEnabled={false}
          />
          <Container sx={{ display: "flex", position: "relative", py: 2 }}>
            <Grid
              component="h2"
              container
              onClick={switchHandler}
              sx={{
                width: "100%",
                lineHeight: "40px",
                p: 0,
                mr: 2,
                my: 0,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                textAlign: "center",
                borderRadius: "8px",
                border: "1px solid #E3E8EB",
                cursor: "pointer",
              }}
            >
              <Grid
                item
                xs={6}
                component="div"
                style={{
                  display: "inline-block",
                  borderRadius: "8px",
                  backgroundColor:
                    category === "shareholders" ? "#3D61B0" : "#ffffff",
                  color: category === "shareholders" ? "#ffffff" : "#96A3AA",
                }}
              >
                Shareholders
              </Grid>
              <Grid
                item
                xs={6}
                component="div"
                style={{
                  display: "inline-block",
                  borderRadius: "8px",
                  backgroundColor:
                    category === "admins" ? "#3D61B0" : "#ffffff",
                  color: category === "admins" ? "#ffffff" : "#96A3AA",
                }}
              >
                Admins
              </Grid>
            </Grid>
            <Button
              sx={{
                // marginLeft: "-51px",
                // paddingLeft: "-51px",
                p: 0,
                border: "1px solid #3D61B0",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
                boxShadow: "0",

                minWidth: "42px",
                // "& .MuiTouchRipple-root": {
                //   width: "40px!important",
                //   background: "#ff0000",
                // },
                ":hover": {
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={addHandler}
            >
              <Add
                fontSize="large"
                htmlColor="#3D61B0"
                sx={{
                  p: 0,
                }}
              />
            </Button>
          </Container>
          <Divider />
          <Grid
            container
            component="main"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              px: 1,
              py: 0,
              m: 0,
              // mt: 9,
            }}
          >
            {category === "shareholders" && <ShareholdersList />}
            {category === "admins" && <AdminsList />}
          </Grid>
          <DetailDialog
            open={newOpen}
            onClose={newOpenClose}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle id="alert-dialog-title">
              <span>Add Member</span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <BtnOption onClick={adminHandler}>
                <span>Propose Admin</span>
                <ArrowForward htmlColor="#BCC0C4" />
              </BtnOption>
              <BtnOption onClick={shareholderHandler}>
                <span>Propose Shareholder</span>
                <ArrowForward htmlColor="#BCC0C4" />
              </BtnOption>
            </DialogContent>
            <Divider />
            <DialogActions>
              <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
            </DialogActions>
          </DetailDialog>
        </>
      )}
      {stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT && (
        <ShareholderAdd />
      )}
      {stepState === STEP.DASHBOARD_MEMBERS_ADMIN_EDIT && <AdminAdd />}
    </>
  );
};

export default Members;
