/**
 * @author Puffer
 * @createdAt 12/19/2022
 * @updatedAt 12/19/2022
 **/

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import IndexPage from "./pages/index";
import CreatePage from "./pages/create";
import DashboardPage from "./pages/wepID/dashboard";
import AdminsPage from "./pages/wepID/admins";
import ChartPage from "./pages/wepID/chart";
import MembersPage from "./pages/wepID/members";
import ProposalsPage from "./pages/wepID/proposals";
import ReceivePage from "./pages/wepID/receive";
import SendPage from "./pages/wepID/send";
import SettingsPage from "./pages/wepID/setting";
import ShareHoldersPage from "./pages/wepID/shareholders";
import TradesPage from "./pages/wepID/trades";
import TransactionsPage from "./pages/wepID/transactions";
import { useWeb3React } from "@web3-react/core";

const ProtectedRoute = ({ children }) => {
  const { account } = useWeb3React();
  if (!account) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route
        path="/create"
        element={
          <ProtectedRoute>
            <CreatePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/admins"
        element={
          <ProtectedRoute>
            <AdminsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/chart"
        element={
          <ProtectedRoute>
            <ChartPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/members"
        element={
          <ProtectedRoute>
            <MembersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/proposals"
        element={
          <ProtectedRoute>
            <ProposalsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/receive"
        element={
          <ProtectedRoute>
            <ReceivePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/send"
        element={
          <ProtectedRoute>
            <SendPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/setting"
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/sharedholders"
        element={
          <ProtectedRoute>
            <ShareHoldersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/trades"
        element={
          <ProtectedRoute>
            <TradesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/wepID/transactions"
        element={
          <ProtectedRoute>
            <TransactionsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
