/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { HomeAppBar } from "../Appbar";
import store from "../../store/store";
import { STORE_KEYS, PAGE_NAME } from "../../store/constant";
import { Container } from "@mui/system";

const Dashboard1 = () => {
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const beforeCreateHandler = () => {
    updateEnterprises((prev) => {
      prev.joined = true;
      prev.pageName = PAGE_NAME.id.welcome_create;
      return prev;
    });
  };

  const joinHandler = () => {
    updateEnterprises((prev) => {
      prev.joined = true;
      prev.pageName = PAGE_NAME.id.join;
      return prev;
    });
  };

  return (
    <>
      <HomeAppBar />
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: "0px 27px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginTop: "24px",
            fontSize: "23px",
            lineHeight: 1,
            fontWeight: "700",
            display: { xs: "block", md: "none" },
          }}
        >
          Congrats! You claimed your World Citizenship
        </Typography>
        <Box
          component="img"
          src="/images/join-us.png"
          alt=""
          sx={{
            mt: "16px",
            mb: "-16px",
            width: { xs: "100%" },
            height: { xs: "fit-content" },
            maxWidth: "100%",
            display: { xs: "block", md: "none" },
          }}
        />
        <Typography
          sx={{
            marginTop: "10px",
            fontSize: "13px",
            lineHeight: "15px",
            fontWeight: "400",
            textAlign: "center",
            display: { xs: "block", md: "none" },
          }}
        >
          World Citizen is a digital distributed citizenship without geographic
          boundaries. Join our community and help build a new global economic
          and political system that provides security, justice and economic
          prosperity to all.
        </Typography>

        <Container
          sx={{
            textAlign: { md: "left" },
            flexDirection: "row",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box
            sx={{
              textAlign: { md: "left" },
              flexDirection: "column",
              width: "56%",
              height: "0px",
              pb: "40%",
              position: "relative",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                marginTop: "50px",
                fontSize: "23px",
                lineHeight: "28px",
                fontWeight: "700",
                textAlign: "left",
                display: { xs: "none", md: "block" },
              }}
            >
              Congrats! You claimed your World Citizenship
            </Typography>
            <Typography
              sx={{
                marginTop: "61px",
                fontSize: "18px",
                lineHeight: "22px",
                display: { xs: "none", md: "block" },
              }}
            >
              World Citizen is a digital distributed citizenship without
              geographic boundaries. Join our community and help build a new
              global economic and political system that provides security,
              justice and economic prosperity to all.
            </Typography>
          </Box>
          <Box
            component="img"
            src={"/images/join-us.png"}
            alt=""
            sx={{
              width: { xs: "auto", md: "44%" },
              height: { xs: "auto", md: "fit-content" },
              display: { xs: "none", md: "block" },
            }}
            style={{}}
          />
        </Container>

        <Button
          fullWidth
          sx={{
            marginTop: "10px",
          }}
          onClick={beforeCreateHandler}
        >
          Create a World Enterprise
        </Button>
        <Button
          fullWidth
          sx={{
            mt: 2,
            mb: 4,
          }}
          onClick={joinHandler}
          variant="outlined"
        >
          Join a World Enterprise
        </Button>
      </Grid>
    </>
  );
};

export default Dashboard1;
