/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState } from "react";
import { CreateAppBar } from "../../components/Appbar";
import { Box, Container } from "@mui/system";
import QRCode from "react-qr-code";
import { IconButton, Typography } from "@mui/material"; // useMediaQuery
import { ContentCopy, Done } from "@mui/icons-material";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";

const Receive = () => {
  const { account } = useWeb3React();
  const location = useLocation();
  const state = location.state;
  const { wepID } = state;

  const navigation = useNavigate();

  const [walletAddr, setWalletAddr] = useState(account || "");
  const [copyed, setCopyed] = useState(false);

  const closeHandler = () => [
    navigation(`/wepID/dashboard`, { state: { wepID } }),
  ];

  const copyToClipboard = () => {
    try {
      copy(walletAddr || "");
      setCopyed(true);
    } catch (e) {
      alert(e.toString() + "ddd");
    }
  };

  useEffect(() => {
    setWalletAddr(account || "");
  }, [account]);

  // const sm = useMediaQuery("(min-width:600px)");

  return (
    <>
      <CreateAppBar title="Receive" close={closeHandler} helpEnabled={false} />
      <Box
        mx="26px"
        mt={10}
        p="54px"
        component="main"
        sx={{
          borderRadius: "12px",
          border: "solid 1px #EEEEEE",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={walletAddr}
          viewBox={`0 0 256 256`}
        />
        <Container sx={{ mt: 3, p: 0, display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "calc((100vw - 160px)/100*5.6)",
                maxWidth: "24ch",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {walletAddr}
            </Typography>
          </Box>
          <IconButton onClick={copyToClipboard}>
            {!copyed ? (
              <ContentCopy sx={{ fontSize: "calc((100vw - 160px)/100*5.6)" }} />
            ) : (
              <Done
                sx={{ fontSize: "calc((100vw - 160px)/100*5.6)" }}
                htmlColor="green"
              />
            )}
          </IconButton>
        </Container>
      </Box>
    </>
  );
};

export default Receive;
