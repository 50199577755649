/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { AppBar } from "../../components/Appbar";
import { ArrowForward } from "@mui/icons-material";
import { AdminAdd } from "../../components/admins";
import { ShareholderAdd } from "../../components/shareholders";
import {
  BtnCancel,
  BtnOption,
  DetailDialog,
  ProposalDetailModal,
  Transition,
} from "../../components/dialog";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/header";

const Proposals = () => {
  const location = useLocation();
  const state = location.state;
  const navigation = useNavigate();

  const { wepID } = state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const [proposals, setProposals] = useState([]);
  const [newOpen, setNewOpen] = useState(false);
  const [idx, setIdx] = useState(-1);
  const [detailOpen, setDetailOpen] = useState(false);

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };
  // add dialog
  const addHandler = () => {
    setNewOpen(true);
  };

  const newOpenClose = () => {
    setNewOpen(false);
  };

  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_ADMIN);
  };
  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_SHAREHOLDER);
  };

  // Detail Dialog

  const onClickItem = (i) => {
    setDetailOpen(true);
    setIdx(i);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const handleReject = () => {
    handleDetailClose();
  };

  const handleAgree = () => {
    handleDetailClose();
  };

  useEffect(() => {
    let proposals = [];
    enterprise.proposals.forEach((proposal) => {
      proposals = [
        ...proposals,
        {
          type: proposal.type,
          walletAddr: proposal.walletAddr,
          state: proposal.isApproved,
          percent:
            proposal.votesYes + proposal.votesNo === 0
              ? 0
              : (proposal.votesYes / (proposal.votesYes + proposal.votesNo)) *
                100,
        },
      ];
    });
    setProposals(proposals);
  }, [enterprise]);

  return (
    <>
      <Header pageTitle={"Proposals"} />
      {stepState === STEP.PROPOSAL_ADMIN && <AdminAdd />}
      {stepState === STEP.PROPOSAL_SHAREHOLDER && <ShareholderAdd />}
      {stepState === STEP.INDEX && (
        <>
          <AppBar
            position="absolute"
            title="Proposals"
            back={backHandler}
            type="add"
            handle={addHandler}
          />
          <Grid
            container
            component="main"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              px: 0,
              mt: proposals.length === 0 ? 0 : 9,
            }}
          >
            <List dense={true} sx={{ width: "100%", pt: 2, px: 0 }}>
              {proposals.map((proposal, i) => {
                return (
                  <Item
                    key={i}
                    type={proposal.type}
                    walletAddr={proposal.walletAddr}
                    state={proposal.state}
                    percent={proposal.percent}
                    onClick={() => {
                      onClickItem(i);
                    }}
                  />
                );
              })}
            </List>
          </Grid>
          <ProposalDetailModal
            open={detailOpen}
            handleClose={handleDetailClose}
            reject={handleReject}
            agree={handleAgree}
            name={
              (enterprise.proposals[idx] && enterprise.proposals[idx].name) ||
              ""
            }
            walletAddr={
              (enterprise.proposals[idx] &&
                enterprise.proposals[idx].walletAddr) ||
              ""
            }
            id={
              (enterprise.proposals[idx] &&
                enterprise.proposals[idx].id.toString()) ||
              ""
            }
            yesNum={
              enterprise.proposals[idx] && enterprise.proposals[idx].votesYes
            }
            noNum={
              enterprise.proposals[idx] && enterprise.proposals[idx].votesNo
            }
          />
          <DetailDialog
            open={newOpen}
            onClose={newOpenClose}
            TransitionComponent={Transition}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xl"
          >
            <DialogTitle id="alert-dialog-title">
              <span>{"New Proposal"}</span>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <BtnOption onClick={adminHandler}>
                <span>Propose Admin</span>
                <ArrowForward htmlColor="#BCC0C4" />
              </BtnOption>
              <BtnOption onClick={shareholderHandler}>
                <span>Propose Shareholder</span>
                <ArrowForward htmlColor="#BCC0C4" />
              </BtnOption>
            </DialogContent>
            <Divider />
            <DialogActions>
              <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
            </DialogActions>
          </DetailDialog>
        </>
      )}
    </>
  );
};

export const Item = ({ type, walletAddr, state, percent, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{
          px: 3,
          py: 1,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemText
          primary={(() => {
            switch (type) {
              case "admin":
                return "Add Admin";
              case "shareholder":
                return "Add Member";
              case "join":
                return "Join";
              case "adminReplace":
                return "Replace Admin";
              default:
                return "";
            }
          })()}
          secondary={walletAddr}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#4B4749",
            textAlign: "left",
            maxWidth: "24ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
        <ListItemText
          primary={state ? "Approved" : "Pending Vote"}
          secondary={`${percent.toFixed(2)}%`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: (() => {
              if (state) return "#36D233";
              else return "#FF2A00";
            })(),
            textAlign: "right",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: (() => {
              if (percent > 50) return "#36D233";
              else return "#FF2A00";
            })(),
            textAlign: "right",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </ListItemButton>
      <Divider />
    </>
  );
};

export default Proposals;
