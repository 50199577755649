/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { Add, ArrowBack, HelpOutlineOutlined } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { HtmlTooltip } from "../dialog";

const AppBar = ({ title, back, handle, type, position, toolTipTitle }) => {
  return (
    <MuiAppBar
      position={position}
      sx={{ boxShadow: "none", borderBottom: "2px solid #E3E8EB" }}
    >
      <Toolbar sx={{ px: 3, pt: 3 }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            back();
          }}
        >
          <ArrowBack fontSize="medium" htmlColor="#4B4749" />
        </IconButton>
        <Typography
          variant="h2"
          component="div"
          sx={{
            flexGrow: 1,
            pr: type === "none" ? "36px!important" : "0px",
          }}
        >
          {title}
        </Typography>
        {(type === "add" || type === "help") && (
          <HtmlTooltip title={toolTipTitle}>
            <IconButton
              onClick={() => {
                if (handle !== undefined) handle();
              }}
            >
              {type === "add" && <Add sx={{ color: "#00C9F2" }} />}
              {type === "help" && <HelpOutlineOutlined />}
            </IconButton>
          </HtmlTooltip>
        )}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
