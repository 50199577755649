import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PhoneNumInput } from "../input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  //   border: "2px solid #fff",
  //   boxShadow: 24,
  p: 4,
};

export default function SMSModal({ open, handleClose }) {
  const [phone, setPhone] = React.useState("");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              The World Enterprise website is designed for a mobile phone. Enter
              your phone number and we'll send you the link
            </Typography>
            <PhoneNumInput value={phone} onChange={setPhone} />
            <Button
              sx={{
                background: "#1c2946",
                padding: { xs: "11px 57px", md: "10px 63px" },
                color: "white",
                fontSize: 14,
                fontWeight: "500",
                lineHeight: "180.5%",
                width: "100%",
                borderRadius: "8px",
                mt: "20px",
              }}
              onClick={handleClose}
            >
              Send
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
