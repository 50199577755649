/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { NewOrder, OrderBook } from "../../components/trades";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import Header from "../../components/header";
import { useLocation, useNavigate } from "react-router-dom";

const Trades = () => {
  const location = useLocation();
  const state = location.state;
  const { wepID } = state;

  const navigation = useNavigate();

  const [stepState] = store.useState(STORE_KEYS.id.step);

  React.useEffect(() => {
    if (stepState === STEP.INDEX) {
      navigation("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header pageTitle={"Trades"} />
      {stepState === STEP.DASHBOARD_TRADE_ADD && <NewOrder wepID={wepID} />}
      {stepState === STEP.DASHBOARD_TRADE_ORDERBOOK && <OrderBook />}
    </>
  );
};

export default Trades;
