/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { QrReader } from "react-qr-reader";

const QRScanModal = ({ open, onClose, qrHandler }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogContent>
        {open && (
          <QrReader
            constraints={{
              facingMode: "user",
            }}
            onResult={(result, error) => {
              if (!!result) {
                qrHandler(result?.text);
                onClose();
              }

              if (!!error) {
                // onClose();
              }
            }}
            style={{ width: "100%" }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QRScanModal;
