/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { AdminAdd, AdminsView } from "../../components/admins";
import Header from "../../components/header";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";

const Admins = () => {
  const [stepState] = store.useState(STORE_KEYS.id.step);

  return (
    <>
      <Header pageTitle="Admins" />
      {stepState === STEP.ADMIN_ADD ||
      stepState === STEP.ADMIN_EDIT ||
      stepState === STEP.DASHBOARD_ADMIN_EDIT ? (
        <AdminAdd />
      ) : (
        <AdminsView />
      )}
    </>
  );
};

export default Admins;
