/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Chip } from "../../components/input";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header";

const Chart = () => {
  const location = useLocation();
  const state = location.state;
  const navigation = useNavigate();

  const [period, setPeriod] = useState("Day");

  const { webID } = state;

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { webID } });
  };

  const changePeriod = (p) => () => {
    console.log(
      p,
      p === "Day" ||
        p === "Week" ||
        p === "Month" ||
        p === "Year" ||
        p === "All"
    );
    if (
      p === "Day" ||
      p === "Week" ||
      p === "Month" ||
      p === "Year" ||
      p === "All"
    ) {
      setPeriod(p);
    }
  };

  // data
  const data = [
    {
      time: new Date(2022, 1, 1, 0, 0),
      price: 1.14,
      amt: 2400,
    },
    {
      time: new Date(2022, 1, 1, 1, 12),
      price: 1.2,
      amt: 2210,
    },
    {
      time: new Date(2022, 1, 1, 1, 17),
      price: 1.35,
      amt: 2290,
    },
    {
      time: new Date(2022, 1, 1, 2, 12),
      price: 1.3,
      amt: 2000,
    },
    {
      time: new Date(2022, 1, 1, 2, 25),
      price: 1.14,
      amt: 2181,
    },
    {
      time: new Date(2022, 1, 1, 3, 47),
      price: 1.32,
      amt: 2500,
    },
    {
      time: new Date(2022, 1, 1, 4, 12),
      price: 1.25,
      amt: 2100,
    },
  ];

  return (
    <>
      <Header pageTitle={"Chart"} />
      <AppBar
        position="static"
        sx={{ boxShadow: "none", borderBottom: "2px solid #E3E8EB" }}
      >
        <Toolbar sx={{ px: 3, pt: 3 }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              back();
            }}
          >
            <ArrowBack fontSize="medium" htmlColor="#4B4749" />
          </IconButton>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            USD&nbsp;/&nbsp;
            <span style={{ color: "#00C9F2" }}> ABC</span>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main">
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {["Day", "Week", "Month", "Year", "All"].map((val, idx) => {
            return (
              <Chip
                key={idx}
                label={val}
                sx={(() => {
                  if (val === period)
                    return {
                      background: "#00C9F2",
                      color: "#fff",
                    };
                  else return {};
                })()}
                onClick={() => {
                  changePeriod(val);
                }}
              />
            );
          })}
        </Box>
        <Box height={"600px"}>
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              data={data}
              margin={{ top: 10, right: 0, left: 30, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00C9F2" stopOpacity={0.13} />
                  <stop offset="95%" stopColor="#00C9F2" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="time"
                tickFormatter={(time) => time.toLocaleTimeString()}
              />
              <YAxis
                id="price"
                orientation="right"
                tickFormatter={(price) => (price === 0 ? "" : `$${price}`)}
                style={{
                  paddingRight: "20px",
                  backgroundColor: "#FF00FF",
                }}
              />
              <CartesianGrid strokeDasharray="3 3" />
              {/* <Tooltip /> */}
              <Area
                type="monotone"
                dataKey="price"
                stroke="#00C9F2"
                fillOpacity={1}
                fill="url(#colorPrice) "
              />
              <ReferenceLine
                y={data[data.length - 1].price}
                strokeWidth={3}
                stroke="#00C9F2"
                strokeDasharray="3 3"
              >
                <Label
                  value={`$${data[data.length - 1].price}`}
                  position="right"
                  fill="#000"
                  content={({ value, viewBox }) => {
                    console.log(viewBox, value);
                    const { x, y, width } = viewBox;
                    return (
                      <foreignObject
                        {...viewBox}
                        width={100}
                        height={50}
                        x={(width || 0) + (x || 0)}
                        y={(y || 0) - 13}
                      >
                        <p
                          style={{
                            display: "inline-block",
                            color: "#fff",
                            backgroundColor: "#00C9F2",
                            margin: "0px",
                            padding: "3px 5px",
                            width: "100%",
                            borderRadius: "15px",
                          }}
                        >
                          {value}
                        </p>
                      </foreignObject>
                    );
                  }}
                >
                  {`$${data[data.length - 1].price}`}
                </Label>
              </ReferenceLine>
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Info title="High Price(wc)" value="$1.35" />
          <Info title="Low Price(wc)" value="$1.14" />
        </Box>
      </Box>
    </>
  );
};

const Info = ({ title, value }) => {
  return (
    <Typography
      variant={"h3"}
      sx={{
        p: "11px 19px",
        borderRadius: "19px",
        backgroundColor: "#FFDB0A",
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "500",
        lineHeight: "18px",
        letterSpacing: "0px",
        textAlign: "center",
      }}
    >
      <span
        style={{
          display: "block",
        }}
      >
        {title}
      </span>
      <span
        style={{
          marginTop: "5px",
          display: "block",
          color: "#3D61B0",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        {value}
      </span>
    </Typography>
  );
};

export default Chart;
