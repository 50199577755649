/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect, useState } from "react";
import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { OrderDetailModal } from "../dialog";
import store from "../../store/store";
import { STORE_KEYS } from "../../store/constant";

const OrderList = ({ type }) => {
  // const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const orders = enterprises.tempEnterprise.orders;

  const [idx, setIdx] = useState(-1);
  const [dlgOpened, setDlgOpened] = useState(false);

  const acceptHandler = () => {
    setDlgOpened(false);
  };

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const onClickItem = (id) => {
    setDlgOpened(true);
    setIdx(id);
  };
  // const replaceHandler = (idx) => () => {
  //   updateEnterprises((prev) => {
  //     prev.toEditAdmin = idx;
  //     return prev;
  //   });
  //   switch (stepState) {
  //     case STEP.CREATE_ADMINS_VIEW:
  //       setStep(STEP.CREATE_ADMIN_EDIT);
  //       break;

  //     case STEP.DASHBOARD_MEMBERS:
  //       setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
  //       break;

  //     default:
  //       setStep(STEP.ADMIN_EDIT);
  //       break;
  //   }
  //   setDlgOpened(false);
  // };

  // const removeHandler = (idx) => () => {
  //   switch (stepState) {
  //     case STEP.CREATE_ADMINS_VIEW:
  //       dispatch(removeAdminState(idx))
  //       break;

  //     default:
  //       dispatch(removeAdminState(idx))
  //       break;
  //   }
  //   setDlgOpened(false)
  // }

  return (
    <>
      <List dense={true} sx={{ width: "100%", pt: 2, px: 0 }}>
        {orders
          .filter((order) => order.orderType === type)
          .sort((a, b) =>
            type === "buy"
              ? a.price >= b.price
                ? -1
                : 1
              : a.price <= b.price
              ? -1
              : 1
          )
          .map((order, idx) => {
            return (
              <Item
                key={idx}
                price={order.price}
                type={order.orderType}
                amount={order.amount}
                onClick={() => {
                  onClickItem(order.id);
                }}
              />
            );
          })}
      </List>
      <OrderDetailModal
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        order={orders.find((order) => {
          console.log(`${idx} ${order.id}`);
          return order.id === idx;
        })}
        accept={acceptHandler}
      />
    </>
  );
};

export const Item = ({ price, type, amount, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{
          px: 3,
          py: 1,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemText
          primary={`$${price} per share`}
          secondary={`${(() => {
            switch (type) {
              case "sell":
                return "Sell";
              case "buy":
                return "Buy";
              default:
                return "";
            }
          })()} Order`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#4B4749",
            textAlign: "left",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
        <ListItemText
          primary={`${amount} shares`}
          secondary={`$${amount * price}`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "18px",
            textAlign: "right",
            color: "#4B4749",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            color: "#4B4749",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </ListItemButton>
      <Divider />
    </>
  );
};

export default OrderList;
