/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Transition;
