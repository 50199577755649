/**
 * @author Puffer
 * @createdAt 12/19/2022
 * @updatedAt 12/19/2022
 **/

export const enterpriseInitialState = {
  joined: false,
  authState: false,
  enterprises: [
    {
      info: {
        name: "ABC Corporation",
        logo: "/images/ABC_Logo1.png",
        tokenName: "ABC",
        description: "",
        isRegisterd: true,
        type: "LLC",
        wepID: "1452",
      },
      shareholders: [
        {
          walletAddr: "0x3218E4c52275D39865f0f23C3Ba06b7b762e2a25",
          numOfShare: 2500,
          firstName: "John",
          lastName: "Appleseed",
        },
        {
          walletAddr: "0x2338E4c52275D39865f0f23C3Ba06b7b762e2a25",
          numOfShare: 1000,
          firstName: "Brian",
          lastName: "Adams",
        },
        {
          walletAddr: "0x5138E4c52275D39865f0f23C3Ba06b7b762e2a25",
          numOfShare: 500,
          firstName: "Damein",
          lastName: "Fork",
        },
      ],
      admins: [
        {
          walletAddr: "0x4255E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isActive: true,
          fullName: "Leah Smith",
          email: "smith@example.com",
          phone: "+4 235 122 2235",
        },
        {
          walletAddr: "0x5368E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isActive: true,
          fullName: "Leo Greene",
          email: "leogreene@example.com",
          phone: "+5 675 122 2235",
        },
        {
          walletAddr: "0x1228E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isActive: false,
          fullName: "Erik Doe",
          email: "erikdoe@example.com",
          phone: "+2 675 122 2235",
        },
        {
          walletAddr: "0x2128E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isActive: false,
          fullName: "Frank Delaron",
          email: "frankdelaron@example.com",
          phone: "+8 675 122 2235",
        },
      ],
      proposals: [
        {
          id: 0,
          name: "John Appleseed",
          type: "admin",
          votesYes: 5,
          votesNo: 1,
          walletAddr: "0x4258E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isApproved: false,
        },
        {
          id: 1,
          name: "William Garcia",
          type: "shareholder",
          votesYes: 1,
          votesNo: 3,
          walletAddr: "0x5168E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isApproved: false,
        },
        {
          id: 2,
          name: "William Garcia",
          type: "shareholder",
          votesYes: 1,
          votesNo: 3,
          walletAddr: "0x5166E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isApproved: false,
        },
        {
          id: 3,
          name: "William Garcia",
          type: "shareholder",
          votesYes: 3,
          votesNo: 1,
          walletAddr: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          isApproved: true,
        },
      ],
      transactions: [
        {
          id: 0,
          asset: "USDC",
          type: "Money Transfer",
          category: "Office Rent",
          amount: 1000,
          note: "Paid to rent office for the business",
          date: new Date(2021, 1, 1, 0, 0, 0, 0),
          isSend: true,
          toAddress: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          fromAddress: "0x6d4C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 1,
          asset: "USDC",
          type: "Money Transfer",
          category: "Salary",
          note: "Paid for employee",
          amount: 250,
          date: new Date(2021, 1, 2, 10, 20, 0, 0),
          isSend: false,
          toAddress: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          fromAddress: "0x6d4C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 2,
          asset: "USDC",
          type: "Money Transfer",
          category: "Travel",
          amount: 1200,
          date: new Date(2021, 1, 3, 0, 0, 0, 0),
          isSend: true,
          toAddress: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          fromAddress: "0x6d4C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 3,
          asset: "USDC",
          type: "Money Transfer",
          category: "Salary",
          amount: 400,
          date: new Date(2021, 1, 4, 0, 20, 0, 0),
          isSend: false,
          toAddress: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          fromAddress: "0x6d4C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 4,
          asset: "USDC",
          type: "Money Transfer",
          category: "Travel",
          note: "Paid for travel",
          amount: 300,
          date: new Date(2021, 1, 5, 0, 0, 0, 0),
          isSend: true,
          toAddress: "0x9556E4c52275D39865f0f23C3Ba06b7b762e2a25",
          fromAddress: "0x6d4C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
      ],
      orders: [
        {
          id: 0,
          orderType: "sell",
          amount: 100,
          price: 15,
          date: new Date(2022, 1, 1),
          maker: "0x855C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 1,
          orderType: "buy",
          amount: 100,
          price: 15,
          date: new Date(2022, 1, 1),
          maker: "0x673C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 2,
          orderType: "buy",
          amount: 120,
          price: 18,
          date: new Date(2022, 1, 1),
          maker: "0x633C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 3,
          orderType: "sell",
          amount: 200,
          price: 12,
          date: new Date(2022, 1, 1),
          maker: "0x233C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 4,
          orderType: "buy",
          amount: 1000,
          price: 11,
          date: new Date(2022, 1, 1),
          maker: "0x252C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 5,
          orderType: "buy",
          amount: 300,
          price: 20,
          date: new Date(2022, 1, 1),
          maker: "0x037C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 6,
          orderType: "sell",
          amount: 500,
          price: 25,
          date: new Date(2022, 1, 1),
          maker: "0x734C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 7,
          orderType: "sell",
          amount: 50,
          price: 45,
          date: new Date(2022, 1, 1),
          maker: "0x623C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 8,
          orderType: "buy",
          amount: 98,
          price: 35,
          date: new Date(2022, 1, 1),
          maker: "0x151C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 9,
          orderType: "sell",
          amount: 30,
          price: 30,
          date: new Date(2022, 1, 1),
          maker: "0x5237743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
        {
          id: 10,
          orderType: "buy",
          amount: 25,
          price: 36,
          date: new Date(2022, 1, 1),
          maker: "0x222C7743Cdbe1FbcE73d80F970855F3eac9582AC",
        },
      ],
      mine: false,
    },
  ],
  tempEnterprise: {
    info: {
      name: "",
      logo: "",
      tokenName: "",
      description: "",
      isRegisterd: false,
      type: "",
    },
    admins: [],
    shareholders: [],
    proposals: [],
    transactions: [],
    orders: [],
    mine: false,
  },
  toEditShareholder: -1,
  toEditAdmin: -1,
  pageName: "",
};
