/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useEffect } from "react";
import { ShareholdersView } from "../../components/shareholders";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import Header from "../../components/header";

const ShareHolders = () => {
  const [, setStep] = store.useState(STORE_KEYS.id.step);

  useEffect(() => {
    setStep(STEP.CREATE_ENTERPRISE_INPUT);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header pageTitle={"Create"} />
      <ShareholdersView />
    </>
  );
};

export default ShareHolders;
