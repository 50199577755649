/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Typography,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { CreateAppBar } from "../Appbar";
import { Input, Select } from "../input";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import { useNavigate } from "react-router-dom";

const NewOrder = ({ wepID }) => {
  const navigation = useNavigate();

  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const { account } = useWeb3React();

  // price in USD (token to USD)
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [balance] = useState(875);
  const [type, setType] = useState("buy");

  const handleInputChange = (type) => (evt) => {
    let value = evt.currentTarget.value;
    let regExp = /[0-9]+/;
    switch (type) {
      case "price":
        regExp = /[0-9]*([.])?[0-9]?[0-9]?/;
        if (regExp.exec(value)?.[0] === value || value === "") {
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;
          setPrice(value);
        }
        break;
      case "amount":
        regExp = /[0-9]+/;
        if (regExp.exec(value)?.[0] === value || value === "") {
          console.log(value, value[0] === "0" && value[1] !== ".");
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);

          setAmount(value);
        }
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const value = evt.target.value;
    switch (type) {
      case "type":
        if (value === "buy" || value === "sell") setType(value);
        break;
      case "moneyType":
        break;

      default:
        break;
    }
  };

  // const handleClickShowQR = () => {}

  const closeHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };

  const transferHandler = () => {
    const order = {
      id: Date.now(),
      orderType: type,
      amount: Number(amount),
      price: Number(price),
      date: new Date(),
      maker: account || "",
    };
    updateEnterprises((prev) => {
      prev.tempEnterprise.orders = [...prev.tempEnterprise.orders, order];
    });
    setStep(STEP.DASHBOARD_TRADE_ORDERBOOK);
  };

  const orderBookHandler = () => {
    setStep(STEP.DASHBOARD_TRADE_ORDERBOOK);
  };

  return (
    <>
      <CreateAppBar
        title="Trade Shares"
        close={closeHandler}
        helpEnabled={false}
      />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{ px: 3, mt: 2 }}
      >
        <Grid item xs={6} mt={0}>
          <Select
            id="type"
            value={type}
            fullWidth
            onChange={handleSelectChange("type")}
            sx={{
              mt: 1,
              backgroundColor: "#3D61B0",
              color: "#FFFFFF",
              "& .MuiSvgIcon-root": {
                color: "#FFFFFF",
              },
            }}
          >
            <MenuItem value="buy">Buy</MenuItem>
            <MenuItem value="sell">Sell</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} mt={0}>
          <Link
            onClick={orderBookHandler}
            sx={{
              display: "block",
              color: "#4B4749",
              fontFamily: "Montserrat",
              textDecoration: "underline",
              fontWeight: 500,
              fontSize: "15px",
              textAlign: "center",
              py: 2.5,
              cursor: "pointer",
            }}
          >
            View Order Book
          </Link>
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="amount">
            Amount of Shares
          </InputLabel>
          <Input
            id="amount"
            fullWidth
            onChange={handleInputChange("amount")}
            value={amount}
            placeholder="0"
            sx={{
              "& input": {
                textAlign: "right",
              },
            }}
          />
          <Typography
            variant="body1"
            px={5}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            Balance: {type === "sell" ? balance - Number(amount) : balance}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="amount">
            Price
          </InputLabel>
          <Input
            id="amount"
            fullWidth
            onChange={handleInputChange("price")}
            value={price}
            placeholder="0.00"
            endAdornment={
              <InputAdornment
                position="start"
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                $
              </InputAdornment>
            }
            sx={{
              "& input": {
                textAlign: "right",
              },
            }}
          />
          <Typography
            variant="body1"
            px={5}
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "20px",
              textAlign: "center",
            }}
          >
            Total to {type === "buy" ? "Send" : "Receive"}: $
            {(Number(amount) * Number(price)).toFixed(2)}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
          <Button fullWidth onClick={transferHandler}>
            Submit Order
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default NewOrder;
