/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React, { useState } from "react";
import { Container, Divider, Grid } from "@mui/material";
import { CreateAppBar } from "../Appbar";
import OrderList from "./orderList";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";

const OrderBook = () => {
  // const navigation = useNavigate();
  // const location = useLocation();
  // const state = location.state;
  // const { wepID } = state;

  const [, setStep] = store.useState(STORE_KEYS.id.step);
  // const [enterprises, , updateEnterprises] = store.useState(
  //   STORE_KEYS.id.enterprises
  // );

  const [category, setCategory] = useState("buy");

  const backHandler = () => {
    setStep(STEP.DASHBOARD_TRADE_ADD);
  };
  const switchHandler = () => {
    switch (category) {
      case "buy":
        setCategory("sell");
        break;
      case "sell":
        setCategory("buy");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <CreateAppBar
        title={"Order Book"}
        close={backHandler}
        helpEnabled={false}
      />
      <Container sx={{ position: "relative", py: 2 }}>
        <Grid
          component="h2"
          container
          onClick={switchHandler}
          sx={{
            width: "100%",
            lineHeight: "40px",
            py: 0,
            my: 0,
            mr: 2,
            ml: 0,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
            borderRadius: "8px",
            border: "1px solid #E3E8EB",
            cursor: "pointer",
          }}
        >
          <Grid
            item
            xs={6}
            component="div"
            style={{
              display: "inline-block",
              borderRadius: "8px",
              backgroundColor: category === "buy" ? "#3D61B0" : "#ffffff",
              color: category === "buy" ? "#ffffff" : "#96A3AA",
            }}
          >
            Buy Orders
          </Grid>
          <Grid
            item
            xs={6}
            component="div"
            style={{
              display: "inline-block",
              borderRadius: "8px",
              backgroundColor: category === "sell" ? "#3D61B0" : "#ffffff",
              color: category === "sell" ? "#ffffff" : "#96A3AA",
            }}
          >
            Sell Orders
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <OrderList type={category} />
      </Container>
    </>
  );
};

export default OrderBook;
