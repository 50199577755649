/**
 * @author Puffer
 * @createdAt 12/28/2022
 * @updatedAt 12/28/2022
 **/

import React, { useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Container,
  Toolbar,
  Link,
  Button,
  IconButton,
  MenuItem,
  Typography,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import store from "../../store/store";
import { STORE_KEYS, PAGE_NAME } from "../../store/constant";
import detectEthereumProvider from "@metamask/detect-provider";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../utils/connectors";
import { mobileCheck } from "../../utils/mobileCheck";
import SelectWalletModal from "../dialog/SelectWalletModal";
const landingTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#0D141E",
  fontSize: { xs: "14px", md: "18px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};
const pages = [PAGE_NAME.id.home, PAGE_NAME.id.aboutUs];
const aboutUsTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#0D141E",
  fontSize: { xs: "23px", md: "35px" },
  lineHeight: { xs: "17px", md: "180.5%" },
};
const AboutUs = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const matches = useMediaQuery("(max-width:1360px)");

  const [isOpen, setIsOpen] = useState(false);
  const { activate } = useWeb3React();
  const [provider, setProvider] = React.useState(null);

  const connectHandler = () => {
    if (mobileCheck() && provider) {
      const connector = connectors.injected;
      activate(connector);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    detectEthereumProvider().then((provider: any) => {
      console.log("Provider detection finished", provider);
      setProvider(provider);
    });
  }, []);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navItemHandle = (page) => {
    updateEnterprises((prev) => {
      prev.pageName = page;
      return prev;
    });
    handleCloseNavMenu();
  };

  return (
    <Box
      sx={{
        bgcolor: aboutUsTheme.primary,
        width: 1,
        pt: "50px",
      }}
    >
      <Container>
        <AppBar
          position="relative"
          color="transparent"
          sx={{
            boxShadow: "none",
            mb: "39px",
          }}
        >
          <Toolbar
            sx={{
              padding: "0 !important",
            }}
          >
            <Box
              component="img"
              src="/images/logo.svg"
              alt=""
              sx={{
                width: {
                  xs: "121px",
                  md: "auto",
                },
              }}
            />
            <Box sx={{ ml: "auto" }}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Link
                    // href="#"
                    underline="none"
                    key={page}
                    sx={{
                      my: 2,
                      color: `${aboutUsTheme.black} !important`,
                      lineHeight: {
                        md: "28.88px",
                      },
                      mr: "59px",
                      display: "block",
                      width: "fit-content",
                    }}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navItemHandle(page);
                    }}
                  >
                    {page}
                  </Link>
                ))}
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{
                    p: "0",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={() => {
                        navItemHandle(page);
                      }}
                    >
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Container>

      {/* world enterprises? */}
      <Box
        sx={{
          backgroundImage: { xs: "none", md: "none" },
          backgroundSize: "50% 100%",
          backgroundPosition: "right top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Typography
          sx={{
            fontSize: aboutUsTheme.fontSize,
            lineHeight: aboutUsTheme.lineHeight,
            fontWeight: "700",
            color: aboutUsTheme.black,
            mb: { xs: "20px", md: "40px" },
            width: { xs: "auto", md: "auto" },
            textAlign: "center",
            display: { xs: "block", md: "none" },
          }}
        >
          World Citizen
        </Typography>
        <Container
          sx={{
            textAlign: { xs: "center", md: "left" },
            py: { xs: "40px", md: "10px" },
            flexDirection: { xs: "column", md: "row" },
            display: { xs: "block", md: "flex" },
          }}
        >
          <Box
            component="img"
            src="/images/aboutus.png"
            alt=""
            sx={{
              width: { xs: "100%" },
              height: { xs: "fit-content" },
              maxWidth: { xs: "100%", md: "100%" },
              display: { xs: "block", md: "none" },
            }}
          />
          <Container
            sx={{
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: aboutUsTheme.fontSize,
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: "700",
                color: aboutUsTheme.black,
                mb: { xs: "20px", md: "20px" },
                width: { xs: "auto", md: "400px" },
                display: { xs: "none", md: "block" },
              }}
            >
              World Citizen the company
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "18px" },
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: "400",
                color: aboutUsTheme.black,
                mt: { xs: "20px", md: "20px" },
                mb: { xs: "20px", md: "20px" },
                width: { xs: "auto", md: matches ? "500px" : "605px" },
              }}
            >
              World Enterprise is an application developed by World Citizen, an
              organization committed to economic freedom, inclusion and equal
              opportunity for all. World Citizen is a digital distributed
              citizenship without geographic boundaries. World Citizen is not
              owned by any individual, rather it is owned and controlled by its
              participants. The community creates tools which allow World
              Citizens to organize, learn and produce without artificial
              barriers that is equitable to all.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "13px", md: "18px" },
                lineHeight: aboutUsTheme.lineHeight,
                fontWeight: "400",
                color: aboutUsTheme.black,
                mb: { xs: "20px", md: "40px" },
                width: { xs: "auto", md: matches ? "500px" : "605px" },
              }}
            >
              World Citizen seeks to create a just digital system where
              individuals are in control of their future for the promotion of
              their wellbeing. We believe in a global economic and political
              system controlled by the people providing security, justice and
              economic prosperity to all.
            </Typography>
          </Container>
          <Box
            component="img"
            src={matches ? "/images/aboutus.png" : "/images/aboutus-md.png"}
            alt=""
            sx={{
              width: { xs: "auto", md: "100%" },
              height: { xs: "auto", md: "fit-content" },
              display: { xs: "none", md: "block" },
            }}
            style={{}}
          />
        </Container>
        <Button variant="outlined"
          sx={{
            m: "0 auto 20px",
            display: "block",
            p: '10px 51px',
            borderColor: landingTheme.black,
            fontSize: landingTheme.fontSize,
            lineHeight: landingTheme.lineHeight,
            color: landingTheme.black,
            backgroundColor: 'transparent',
            fontWeight: 600,
          }}
          onClick={connectHandler}
        >Connect</Button>

      </Box>


      {/* footer */}
      <Box
        sx={{
          py: { xs: "37px", md: "58px" },
        }}
      >
        <Container>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              textAlign: { xs: "center", md: "left" },
              justifyContent: "space-between ",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/images/logo.svg"
              alt=""
              sx={{
                width: {
                  xs: "121px",
                  md: "auto",
                },
                mb: { xs: "20px", md: "0" },
              }}
            />
            <Box
              sx={{
                mb: { xs: "20px", md: "0" },
              }}
            >
              <Button
                sx={{
                  background: "transparent",
                  border: {
                    xs: "1.5px solid #000000",
                    md: "3px solid #000000",
                  },
                  width: { xs: "20px", md: "48px" },
                  height: { xs: "20px", md: "48px" },
                  mr: { xs: "17px", md: "40px" },
                  // p: {xs: "5px", md: "11px !important"},
                  minWidth: 0,
                }}
              >
                <Box
                  component="img"
                  src="/images/facebook.svg"
                  sx={{
                    height: { xs: "11px", md: "auto" },
                  }}
                />
              </Button>
              <Button
                sx={{
                  background: "transparent",
                  // border: "3px solid #000000",
                  border: {
                    xs: "1.5px solid #000000",
                    md: "3px solid #000000",
                  },
                  width: { xs: "20px", md: "48px" },
                  height: { xs: "20px", md: "48px" },
                  minWidth: 0,
                }}
              >
                <Box
                  component="img"
                  src="/images/twitter.svg"
                  sx={{
                    height: { xs: "11px", md: "auto" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                mb: { xs: "20px", md: "0" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  lineHeight: { xs: "17px", md: "20px" },
                  fontWeight: "500",
                  color: "black",
                }}
              >
                ©2022 | All rights reserved by WE Enterprise
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </Box>
  );
};

export default AboutUs;
