/**
 * @author Puffer
 * @createdAt 12/20/2022
 * @updatedAt 12/20/2022
 **/

import React from "react";
import { ProfileAppBar } from "../../components/Appbar";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowForwardIos,
  CreditCard,
  EditNotificationsOutlined,
  WorkOutline,
} from "@mui/icons-material";
import {
  EnterpriseCreate,
  EnterpriseReview,
} from "../../components/enterprise";
import store from "../../store/store";
import { STORE_KEYS, STEP } from "../../store/constant";
import { useNavigate, useLocation } from "react-router-dom";

const Settings = () => {
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);

  const location = useLocation();
  const { wepID } = location.state;
  console.log("#############", wepID);
  const navigation = useNavigate();

  const enterprise = enterprises.tempEnterprise;

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID } });
  };

  const companyProfile = () => {
    setStep(STEP.SETTING_COMPANY_REVIEW);
  };
  const notificationSetting = () => {};
  const currency = () => {};

  return (
    <>
      {step === STEP.INDEX && (
        <>
          <ProfileAppBar
            logo={enterprise.info.logo}
            name={enterprise.info.name}
            ID={enterprise.info.wepID || ""}
            back={back}
          />
          <Box mt={-6} px={2} py={3} sx={{ backgroundColor: "#FFFFFF" }}>
            <Typography variant="h5">Company setting</Typography>
            <Divider sx={{ mt: 1 }} />
            <List dense={true} sx={{ width: "100%", mt: 0, p: 0 }}>
              <Item
                Icon={WorkOutline}
                title="Company Profile"
                onClick={companyProfile}
              />
              <Item
                Icon={EditNotificationsOutlined}
                title="Notification Settings"
                onClick={notificationSetting}
              />
              <Item
                Icon={CreditCard}
                title="Currency Settings"
                optional="USD($)"
                onClick={currency}
              />
            </List>
          </Box>
        </>
      )}
      {step === STEP.SETTING_COMPANY_REVIEW && <EnterpriseReview />}
      {step === STEP.SETTING_COMPANY_EDIT && <EnterpriseCreate />}
    </>
  );
};

const Item = ({ Icon, title, optional, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{ px: 0, py: 1 }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemAvatar sx={{ minWidth: "0px", mr: 2 }}>
          <Icon htmlColor="#241F21" />
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
          }}
        />
        {optional && (
          <ListItemText
            primary={optional}
            primaryTypographyProps={{
              textAlign: "right",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "18px",
              color: "#3D61B0",
            }}
          />
        )}
        <IconButton edge="end" sx={{ mr: 0 }}>
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </ListItemButton>
      <Divider />
    </>
  );
};

export default Settings;
